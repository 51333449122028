import {
	FieldAccessor,
	SingleLineTextInputProps,
	TextInput,
	SimpleRelativeSingleField,
	SimpleRelativeSingleFieldProps,
} from '@contember/admin'
import * as React from 'react'

export type CentsFieldProps = SimpleRelativeSingleFieldProps &
	Omit<SingleLineTextInputProps, 'value' | 'onChange' | 'validationState' | 'allowNewlines'>

export const CentsField = SimpleRelativeSingleField<CentsFieldProps, number>(
	(fieldMetadata, { defaultValue, ...props }) => {
		const generateOnChange = (data: FieldAccessor<number>) => (e: React.ChangeEvent<HTMLInputElement>) => {
			data.updateValue(Math.round(parseFloat(e.target.value) * 100))
		}
		return (
			<TextInput
				value={
					typeof fieldMetadata.field.currentValue === 'number'
						? (fieldMetadata.field.currentValue / 100).toFixed(2)
						: '0'
				}
				step={0.01}
				onChange={generateOnChange(fieldMetadata.field)}
				validationState={fieldMetadata.field.errors.length ? 'invalid' : undefined}
				{...props}
				style={props.style as any}
			/>
		)
	},
	'CentsField',
)
