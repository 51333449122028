import { AltPersistButton, MultiEditPage, TextField } from '@contember/admin'
import * as React from 'react'
import { EditButton, LocaleSideDimension } from '../../components'
import { careerCategoryPageName, careerPositionOrderPageName } from './CareerPageNames'

export const careerCategoryRoutes = {
	[careerCategoryPageName]: { path: '/career/categories' },
}

export const CareerCategoryOrderPage = (
	<MultiEditPage
		entities="CareerCategory"
		pageName={careerCategoryPageName}
		rendererProps={{
			title: 'Kategorie pozic',
			sortableBy: 'order',
			persistButtonComponent: AltPersistButton,
		}}
	>
		<TextField label="Interní název" field="name" />
		<LocaleSideDimension>
			<TextField label="Název" field="name" />
		</LocaleSideDimension>
		<EditButton pageName={careerPositionOrderPageName}>Seřadit pozice</EditButton>
	</MultiEditPage>
)
