import {
	AltPersistButton,
	Box,
	Component,
	CreatePage,
	EditPage,
	Field,
	Literal,
	LocationField,
	MultiSelectField,
	NavigateBackButton,
	PageLinkButton,
	Repeater,
	RichTextField,
	SelectField,
	TableCell,
	TablePage,
	TextAreaField,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import {
	DocumentListField,
	EditButton,
	ImageListField,
	LinkUrlField,
	LocaleSideDimension,
	SeoForm,
} from '../../components'
import { YoutubeVideoListField } from '../../components/YoutubeVideoListField'
import { createReindexCallback } from '../../utils/createReindexCallback'

export const contactPlaceListPageName = 'contactPlaceList'
export const contactPlaceCreatePageName = 'contactPlaceCreate'
export const contactPlaceEditPageName = 'contactPlaceEdit'

export const contactPlaceRoutes = {
	[contactPlaceListPageName]: { path: '/contact/places' },
	[contactPlaceCreatePageName]: { path: '/contact/places/new' },
	[contactPlaceEditPageName]: { path: '/contact/places/:id' },
}

const listPageName = contactPlaceListPageName
const createPageName = contactPlaceCreatePageName
const editPageName = contactPlaceEditPageName
const entityName = 'ContactPlace'
const addNewTitle = 'Přidat místo'
const listingTitle = 'Místa'
const editTitle = 'Upravit místo'
const grid = (
	<>
		<TableCell>
			<Field field={'city'} />
		</TableCell>
		<TableCell>
			<LocaleSideDimension>
				<Field field={'name'} />
			</LocaleSideDimension>
		</TableCell>
	</>
)

export const ContactPlaceForm = Component(
	() => (
		<>
			<SelectField
				field="type"
				label="Typ"
				options={[
					{ value: new Literal('dealer'), label: 'Prodejce' },
					{ value: new Literal('store'), label: 'Výrobní a prodejní závod' },
					{ value: new Literal('showroom'), label: 'Centrum inspirace' },
				]}
			/>
			<LocaleSideDimension>
				<Box>
					<TextField field="name" label="Jméno" />
					<RichTextField field="note" label="Popis" />
				</Box>
			</LocaleSideDimension>
			<LinkUrlField label={'URL'} derivedFrom={`locales(locale.code='cs').name`} persistedSoftPrefix={'prodejci/'} />
			<TextField field="city" label="Město" />
			<TextField field="zip" label="PSČ" />
			<TextAreaField field="address" label="Adresa" />
			<LocationField latitudeField={'gpsLat'} longitudeField={'gpsLng'} label={'Pozice'} />
			<TextField field="phone" label="Telefon" />
			<TextField field="email" label="E-mail" />
			<TextField field="website" label="Web" />

			<DocumentListField field={'documents'} />
			<ImageListField field={'gallery'} />
			<LocaleSideDimension>
				<YoutubeVideoListField field="youtubeVideos" />
			</LocaleSideDimension>
			<MultiSelectField field={'products'} label={'Produkty'} options={'Product.internalName'} />
			<Repeater field={'openingHours'} sortableBy={'order'} label={'Otevírací doba'}>
				<TextField field="title" label="Titulek" />
				<TextField field="value" label="Hodnota" />
				<Repeater field={'options'} sortableBy={'order'} label={'Podhodnoty'}>
					<TextField field="title" label="Titulek" />
					<TextField field="value" label="Hodnota" />
				</Repeater>
			</Repeater>
			<LocaleSideDimension>
				<TextAreaField field="description" label="Poznámka k otevírací době" />
			</LocaleSideDimension>
			<LocaleSideDimension>
				<SeoForm titleDerivedFrom={'name'} />
			</LocaleSideDimension>
		</>
	),
	'ContactPlaceForm',
)

export const ContactPlaceCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		// onBeforePersist={createReindexCallback('reindexContactPlace')}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
	>
		<ContactPlaceForm />
	</CreatePage>
)
export const ContactPlaceEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		onBeforePersist={createReindexCallback('reindexContactPlace')}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<ContactPlaceForm />
	</EditPage>
)

export const ContactPlaceListPage = (
	<TablePage
		pageName={listPageName}
		entities={entityName}
		orderBy={'city'}
		rendererProps={{
			title: listingTitle,
			actions: <PageLinkButton to={createPageName}>{addNewTitle}</PageLinkButton>,
		}}
	>
		{grid}
		<TableCell shrunk>
			<EditButton pageName={editPageName} />
		</TableCell>
	</TablePage>
)
