import { Box, BoxProps, Button, Collapsible, Icon } from '@contember/admin'
import * as React from 'react'

export interface CollapsibleBoxProps extends Pick<BoxProps, 'heading' | 'distinction' | 'children'> {}

export const CollapsibleBox = React.memo<CollapsibleBoxProps>(({ heading, distinction, children }) => {
	const [isExpanded, setIsExpanded] = React.useState(false)
	const toggle = React.useCallback(() => {
		setIsExpanded(!isExpanded)
	}, [isExpanded])

	return (
		<Box
			heading={heading}
			distinction={distinction}
			actions={
				<Button size="small" onClick={toggle}>
					{isExpanded ? 'zabalit' : 'zobrazit více'} <Icon blueprintIcon={isExpanded ? 'chevron-up' : 'chevron-down'} />
				</Button>
			}
		>
			<Collapsible expanded={isExpanded}>{children}</Collapsible>
		</Box>
	)
})
