import { GenericPage, Table, TableCell, TableRow, TitleBar } from '@contember/admin'
import * as React from 'react'

export const DashboardPage = (
	<GenericPage pageName="dashboard">
		<TitleBar>Presbeton</TitleBar>
		<Table size="default">
			<TableRow>
				<TableCell>
					<b>Beta</b>
				</TableCell>
				<TableCell shrunk justification="justifyEnd">
					<a href="https://presbeton-prod.mgw.cz/" target="_blank" rel="noopener" style={{ whiteSpace: 'nowrap' }}>
						presbeton-prod.mgw.cz
					</a>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					<b>Produkce</b>
				</TableCell>
				<TableCell shrunk justification="justifyEnd">
					<a href="https://www.presbeton.cz/" target="_blank" rel="noopener">
						www.presbeton.cz
					</a>
				</TableCell>
			</TableRow>
		</Table>
	</GenericPage>
)
