import { AltPersistButton, MultiEditPage, NavigateBackButton, TextAreaField, TextField } from '@contember/admin'
import * as React from 'react'
import { contactCountryListPageName, contactOfficeListPageName } from './ContactPageNames'

export const contactOfficeRoutes = {
	[contactOfficeListPageName]: { path: '/contact/offices/:id' },
}

export const ContactOfficeListPage = (
	<MultiEditPage
		pageName={contactOfficeListPageName}
		entities="ContactOffice[country.id = $id]"
		setOnCreate="(country.id = $id)"
		rendererProps={{
			title: 'Obchodní informace',
			sortableBy: 'order',
			navigation: <NavigateBackButton to={contactCountryListPageName}>Země</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<TextField field="name" label="Název" />
		<TextAreaField field="address" label="Adresa" />
	</MultiEditPage>
)
