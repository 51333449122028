import {
	AltPersistButton,
	Component,
	MultiEditPage,
	NavigateBackButton,
	TextAreaField,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { contactCompanyListPageName, contactCountryListPageName } from './ContactPageNames'

export const contactCompanyRoutes = {
	[contactCompanyListPageName]: { path: '/contact/companies/:id' },
}

export const ContactCompanyForm = Component(
	() => (
		<>
			<TextField field="name" label="Jméno" />
			<TextAreaField field="address" label="Adresa" />
			<TextField field="companyId" label="IČO" />
			<TextField field="vatId" label="DIČ" />
			<TextField field="legalStatus" label="Právní forma" />
			<TextField field="referenceNumber" label="Spisová značka" />
			<TextField field="phone" label="Telefon" />
			<TextField field="fax" label="Fax" />
			<TextField field="email" label="E-mail" />
		</>
	),
	'ContactCompanyForm',
)

export const ContactCompanyListPage = (
	<MultiEditPage
		pageName={contactCompanyListPageName}
		entities="ContactCompany[country.id = $id]"
		setOnCreate="(country.id = $id)"
		rendererProps={{
			title: 'Společnosti',
			navigation: <NavigateBackButton to={contactCountryListPageName}>Země</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<ContactCompanyForm />
	</MultiEditPage>
)
