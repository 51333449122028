import { ProjectConfig } from '@contember/admin'
import { translationRoutes } from '../../../plugins/translations/admin/routes'

const routes = {
	dashboard: { path: '/' },
	...translationRoutes(),
}

const config: ProjectConfig[] = [
	{
		project: 'rekola',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
