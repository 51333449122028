import { ProjectConfig } from '@contember/admin'

const routes = {
	dashboard: { path: '/' },

	pageList: { path: '/pages' },
	pageCreate: { path: '/pages/new' },
	pageEdit: { path: '/pages/:id' },
	postList: { path: '/posts' },
	postCreate: { path: '/posts/new' },
	postEdit: { path: '/posts/:id' },
	headerMenu: { path: '/menu' },
	social: { path: '/social' },

	// locales
	locales: { path: '/locales' },
}
const config: ProjectConfig[] = [
	{
		project: 'eyeoncuba',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
