import {
	AltPersistButton,
	Component,
	CreatePage,
	EditPage,
	Field,
	MultiEditPage,
	NavigateBackButton,
	PageLinkButton,
	Repeater,
	SelectField,
	TableCell,
	TablePage,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { EditButton, LinkUrlField, SeoForm } from '../../components'
import {
	blogCategoryCreatePageName,
	blogCategoryEditPageName,
	blogCategoryListPageName,
	blogCategoryOrderPageName,
} from './BlogPageNames'

export const blogCategoryRoutes = {
	[blogCategoryListPageName]: { path: '/blog/categories' },
	[blogCategoryCreatePageName]: { path: '/blog/categories/new' },
	[blogCategoryOrderPageName]: { path: '/blog/categories/order' },
	[blogCategoryEditPageName]: { path: '/blog/categories/:id' },
}

const listPageName = blogCategoryListPageName
const createPageName = blogCategoryCreatePageName
const editPageName = blogCategoryEditPageName
const entityName = 'BlogCategory'
const addNewTitle = 'Přidat kategorii'
const listingTitle = 'Kategorie'
const editTitle = 'Upravit kategorii'
const grid = (
	<TableCell>
		<Field field={'name'} />
	</TableCell>
)

export const BlogCategoryForm = Component(
	() => (
		<>
			<TextField field="name" size="large" label="Název" />
			<LinkUrlField derivedFrom="name" label="URL" persistedSoftPrefix={'magazin/'} />
			<SelectField field="locale" options="Locale.caption" label="Jazyk" />
			<Repeater field="topics" sortableBy="order" label="Témata">
				<TextField field="name" label="Název" />
			</Repeater>
			<SeoForm titleDerivedFrom={'name'} />
		</>
	),
	'BlogCategoryForm',
)
export const BlogCategoryCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
		orderField="order"
	>
		<BlogCategoryForm />
	</CreatePage>
)
export const BlogCategoryEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<BlogCategoryForm />
	</EditPage>
)

export const BlogCategoryListPage = (
	<TablePage
		pageName={listPageName}
		entities={entityName}
		rendererProps={{
			title: listingTitle,
			actions: (
				<>
					<PageLinkButton to={blogCategoryCreatePageName}>{addNewTitle}</PageLinkButton>
					<PageLinkButton to={blogCategoryOrderPageName}>Seřadit kategorie</PageLinkButton>
				</>
			),
		}}
	>
		{grid}
		<TableCell shrunk>
			<EditButton pageName={editPageName} />
		</TableCell>
	</TablePage>
)

export const BlogCategoryOrderPage = (
	<MultiEditPage
		entities="BlogCategory"
		pageName={blogCategoryOrderPageName}
		rendererProps={{
			title: 'Seřadit kategorie',
			sortableBy: 'order',
			enableAddingNew: false,
			enableRemoving: false,
		}}
	>
		<TextField label="Name" field="name" readOnly />
	</MultiEditPage>
)
