import { ProjectConfig } from '@contember/admin'
import { translationRoutes } from '../../../plugins/translations/admin/routes'

const routes = {
	dashboard: { path: '/' },

	homepage: { path: '/homepage' },
	aboutpage: { path: '/aboutpage' },
	contactpage: { path: '/contactpage' },
	designerspage: { path: '/designerspage' },
	productspage: { path: '/productspage' },
	referencespage: { path: '/referencespage' },

	productList: { path: '/products' },
	productCreate: { path: '/products/new' },
	productEdit: { path: '/products/:id' },

	productPalleteList: { path: '/productPalletes' },
	productPalleteCreate: { path: '/productPalletes/new' },
	productPalleteEdit: { path: '/productPalletes/:id' },

	productVariantList: { path: '/productVariants' },
	productVariantCreate: { path: '/productVariants/new' },
	productVariantEdit: { path: '/productVariants/:id' },

	referenceList: { path: '/references' },
	referenceCreate: { path: '/references/new' },
	referenceEdit: { path: '/references/:id' },

	quoteList: { path: '/quotes' },
	quoteCreate: { path: '/quotes/new' },
	quoteEdit: { path: '/quotes/:id' },

	tagList: { path: '/tags' },

	reservationTimes: { path: '/reservationTimes' },

	reservationSlotCreate: { path: '/reservationSlotCreate' },
	reservationSlotList: { path: '/reservationSlotList' },

	footerMenu: { path: '/footer-menu' },
	headerMenu: { path: '/header-menu' },

	locales: { path: '/locales' },

	redirects: { path: '/redirects/' },

	...translationRoutes(),

	// tenant
	tenantChangePassword: { path: '/change-password' },
	tenantUsers: { path: '/users' },
	tenantInviteUser: { path: '/invite-user' },
	tenantEditUser: { path: '/edit-user/:id' },
}
const config: ProjectConfig[] = [
	{
		project: 'trideco',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
