import { ProjectConfig } from '@contember/admin'

const routes = {
	dashboard: { path: '/' },

	pageList: { path: '/pages' },
	pageCreate: { path: '/pages/new' },
	pageEdit: { path: '/pages/:id' },
	postList: { path: '/posts' },
	postCreate: { path: '/posts/new' },
	postEdit: { path: '/posts/:id' },
	courseList: { path: '/courses' },
	courseEdit: { path: '/courses/:id' },
	courseCreate: { path: '/courses/new' },
	eventList: { path: '/events' },
	eventEdit: { path: '/events/:id' },
	eventCreate: { path: '/events/new' },
	footerPage: { path: '/footer' },
	bannerPage: { path: '/banner' },
	newsPage: { path: '/newsPage' },
	headerMenu: { path: '/menu' },

	// tenant
	tenantChangePassword: { path: '/change-password' },
	tenantUsers: { path: '/users' },
	tenantInviteUser: { path: '/invite-user' },
	tenantEditUser: { path: '/edit-user/:id' },
}
const config: ProjectConfig[] = [
	{
		project: 'paulusyoga',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
