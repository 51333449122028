import { ProjectConfig } from '@contember/admin'

const routes = {
	dashboard: { path: '/' },

	colorPalletes: { path: '/colorPalletes' },

	eventCreate: { path: '/event/create' },
	eventEdit: { path: '/event:id' },
	eventList: { path: '/eventList' },

	locales: { path: '/locales' },

	// tenant
	tenantChangePassword: { path: '/change-password' },
	tenantUsers: { path: '/users' },
	tenantInviteUser: { path: '/invite-user' },
	tenantEditUser: { path: '/edit-user/:id' },
}
const config: ProjectConfig[] = [
	{
		project: 'goodlok',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
