import {
	AltPersistButton,
	Component,
	CreatePage,
	EditPage,
	Field,
	NavigateBackButton,
	NumberField,
	PageLinkButton,
	Repeater,
	SelectField,
	TableCell,
	TablePage,
	TextAreaField,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { EditButton, ImageField, ImageListField, IsPublishedField, LocaleSideDimension } from '../../components'
import {
	productCompositionCreatePageName,
	productCompositionEditPageName,
	productCompositionListPageName,
} from './ProductPageNames'

export const productCompositionRoutes = {
	[productCompositionListPageName]: { path: '/product/compositions' },
	[productCompositionCreatePageName]: { path: '/product/compositions/new' },
	[productCompositionEditPageName]: { path: '/product/compositions/:id' },
}

const listPageName = productCompositionListPageName
const createPageName = productCompositionCreatePageName
const editPageName = productCompositionEditPageName
const entityName = 'ProductComposition'
const addNewTitle = 'Přidat skladebnost'
const listingTitle = 'Skladebnosti'
const editTitle = 'Upravit skladebnost'
const grid = (
	<TableCell>
		<Field field={'internalName'} />
	</TableCell>
)
export const ProductCompositionForm = Component(
	() => (
		<>
			<TextField field="internalName" label="Interní název" />
			<ImageField field="listingImage" label="Obrázek pro výpisy" allowImageDisconnect />
			<ImageListField field="images" label="Obrázky" />
			<Repeater label={'Položky'} field={'items'} sortableBy={'order'}>
				<SelectField field={'product'} label={'Produkt'} options={'Product.internalName'} />
				<NumberField field={'count'} label={'Počet'} defaultValue={1} />
			</Repeater>
			<LocaleSideDimension>
				<TextField field="name" label="Název" />
				<TextAreaField field="size" label="Velikost" />
			</LocaleSideDimension>
		</>
	),
	'ProductCompositionForm',
)

export const ProductCompositionCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			side: <IsPublishedField />,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
	>
		<ProductCompositionForm />
	</CreatePage>
)
export const ProductCompositionEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			side: <IsPublishedField />,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<ProductCompositionForm />
	</EditPage>
)

export const ProductCompositionListPage = (
	<TablePage
		pageName={listPageName}
		entities={entityName}
		rendererProps={{
			title: listingTitle,
			actions: <PageLinkButton to={createPageName}>{addNewTitle}</PageLinkButton>,
		}}
	>
		{grid}
		<TableCell shrunk>
			<EditButton pageName={editPageName} />
		</TableCell>
	</TablePage>
)
