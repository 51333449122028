export * from './CareerCategoryPages'
export * from './CareerPageNames'
export * from './CareerPositionPages'

import { careerCategoryRoutes } from './CareerCategoryPages'
import { careerPositionRoutes } from './CareerPositionPages'

export const careerRoutes = {
	...careerPositionRoutes,
	...careerCategoryRoutes,
}
