import { ProjectConfig } from '@contember/admin'
import { tenantRoutes } from '../../../plugins/tenant/admin/routes'

const routes = {
	dashboard: { path: '/' },

	// redirects
	redirects: { path: '/redirects' },

	cookieBar: { path: '/cookieBar' },

	frontPage: { path: '/frontpage' },
	blogPage: { path: '/blogpage' },
	contactPage: { path: '/contactpage' },
	projectsPage: { path: '/projectsPage' },
	referencePage: { path: '/referencePage' },
	developerProjectsPage: { path: '/developerProjectsPage' },
	errorPage: { path: '/errorPage' },

	headerMenu: { path: '/headerMenu' },
	footerMenu: { path: '/footerMenu' },

	genericPages: { path: '/genericPages' },
	genericPageCreate: { path: '/genericPages/new' },
	genericPageEdit: { path: '/genericPages/:id' },

	actualities: { path: '/actualities' },
	actualityCreate: { path: '/actualities/new' },
	actualityEdit: { path: '/actualities/:id' },

	references: { path: '/references' },
	referenceCreate: { path: '/references/new' },
	referenceEdit: { path: '/references/:id' },

	projects: { path: '/projects' },
	projectImages: { path: '/projectImages' },
	projectDetail: { path: '/project/:id' },
	projectContactForm: { path: '/projectContactForm' },
	filledProjectForms: { path: '/filledProjectForms' },
	filledProjectFormDetail: { path: '/filledProjectForms/:id' },

	sellers: { path: '/sellers' },
	sellerDetail: { path: '/seller/:id' },
	apiData: { path: '/apiData' },

	...tenantRoutes(),
}
const config: ProjectConfig[] = [
	{
		project: 'alle',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
