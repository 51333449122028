import { ProjectConfig } from '@contember/admin'
import { translationRoutes } from '../../../plugins/translations/admin/routes'

const routes = {
	dashboard: { path: '/' },

	aboutPage: { path: '/aboutpage' },
	contactPage: { path: '/contactpage' },
	frontPage: { path: '/frontpage' },
	workWithUsPage: { path: '/workWithUspage' },
	hrPage: { path: '/hrpage' },
	presentationPage: { path: '/presentationpage' },
	careerPage: { path: '/careerpage' },
	errorPage: { path: '/errorpage' },

	genericPageList: { path: '/genericpages' },
	genericPageCreate: { path: '/genericpages/new' },
	genericPageEdit: { path: '/genericpages/:id' },

	headerMenu: { path: '/header-menu' },
	footerMenu: { path: '/footer-menu' },

	hrForm: { path: '/hrForm' },
	hrFormDataList: { path: '/hrFormDataList' },
	hrFormDataDetail: { path: '/hrFormData/:id' },

	cookieBar: { path: '/cookieBar' },

	topMagazineArticlesSectionPage: { path: '/top-magazine-articles' },

	careerItemList: { path: '/careerItems' },
	careerItemCreate: { path: '/careerItems/new' },
	careerItemEdit: { path: '/careerItems/:id' },

	careerCategoryList: { path: '/careerCategories' },
	careerCategoryCreate: { path: '/careerCategories/new' },
	careerCategoryEdit: { path: '/careerCategories/:id' },

	careerForm: { path: '/careerForm' },
	careerFormDataList: { path: '/careerFormDataList' },
	careerFormDataDetail: { path: '/careerFormData/:id' },

	presentationItemList: { path: '/presentationItems' },
	presentationItemCreate: { path: '/presentationItems/new' },
	presentationItemEdit: { path: '/presentationItems/:id' },

	presentationForm: { path: '/presetationForm' },
	presentationFormDataList: { path: '/presetationFormDataList' },
	presentationFormDataDetail: { path: '/presetationFormData/:id' },

	presentationCategoryList: { path: '/presentationCategories' },

	projectList: { path: '/projects' },
	projectCreate: { path: '/projects/new' },
	projectEdit: { path: '/projects/:id' },

	redirects: { path: '/redirects/' },

	locales: { path: '/locales' },

	...translationRoutes(),

	// tenant
	tenantChangePassword: { path: '/change-password' },
	tenantUsers: { path: '/users' },
	tenantInviteUser: { path: '/invite-user' },
	tenantEditUser: { path: '/edit-user/:id' },
}
const config: ProjectConfig[] = [
	{
		project: 'lmceu',
		stage: 'live',
		component: () => import('./src/live'),
		routes: routes,
	},
]

export default config
