import {
	Component,
	ConcealableField,
	DateFieldInner,
	Field,
	FormGroup,
	useEnvironment,
	useMutationState,
	useRelativeSingleField,
} from '@contember/admin'
import * as React from 'react'

const fieldName = 'publishedAt'
const now = new Date()

export const IsPublishedField = Component(
	() => {
		const dateField = useRelativeSingleField<string>(fieldName)
		const environment = useEnvironment()
		const isMutating = useMutationState()

		const renderConcealedValue = React.useCallback(() => {
			const date = dateField.currentValue ? new Date(dateField.currentValue) : now

			return new Intl.DateTimeFormat('en-GB', {
				day: '2-digit',
				month: 'short',
				year: 'numeric',
			}).format(date)
		}, [dateField.currentValue])

		return (
			<FormGroup label="Datum publikace">
				<ConcealableField renderConcealedValue={renderConcealedValue} isExtended>
					{({ inputRef, onFocus, onBlur }) => (
						<DateFieldInner
							fieldMetadata={{
								field: dateField,
								environment,
								isMutating,
							}}
							onFocus={onFocus}
							onBlur={onBlur}
							ref={inputRef}
							dateFormat="dd MMM yyyy"
						/>
					)}
				</ConcealableField>
			</FormGroup>
		)
	},
	() => <Field field={fieldName} defaultValue={now.toISOString()} isNonbearing />,
	'IsPublishedField',
)
