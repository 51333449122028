import {
	AltPersistButton,
	Component,
	CreatePage,
	EditPage,
	Field,
	MultiSelectField,
	NavigateBackButton,
	PageLinkButton,
	Repeater,
	SelectField,
	TableCell,
	TablePage,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { EditButton, ImageField, ImagePreviewField, LocaleSideDimension } from '../../components'
import { referenceCreatePageName, referenceEditPageName, referenceListPageName } from './ReferencePageNames'

export const referenceItselfRoutes = {
	[referenceListPageName]: { path: '/references' },
	[referenceCreatePageName]: { path: '/references/new' },
	[referenceEditPageName]: { path: '/references/:id' },
}

const listPageName = referenceListPageName
const createPageName = referenceCreatePageName
const editPageName = referenceEditPageName
const entityName = 'Reference'
const addNewTitle = 'Přidat realizaci'
const listingTitle = 'Realizace'
const editTitle = 'Upravit realizaci'
const grid = (
	<>
		<TableCell shrunk>
			<ImagePreviewField srcField="image.url" standalone />
		</TableCell>
		<TableCell>
			<Field field="internalName" />
		</TableCell>
	</>
)

export const ReferenceForm = Component(
	() => (
		<>
			<TextField field="internalName" label="Interní název" />
			<ImageField field="image" label="Obrázek" allowImageDisconnect />
			<MultiSelectField field="products" label="Produkty" options="Product.internalName" />
			<Repeater field="categories" label={undefined} setOnCreate={'(order = 99)'}>
				<SelectField field={'category'} label={undefined} options={'ReferenceCategory.internalName'} />
			</Repeater>
			<LocaleSideDimension>
				<TextField field="title" label="Titulek" />
			</LocaleSideDimension>
		</>
	),
	'ReferenceForm',
)

export const ReferenceCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
	>
		<ReferenceForm />
	</CreatePage>
)
export const ReferenceEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<ReferenceForm />
	</EditPage>
)

export const ReferenceListPage = (
	<TablePage
		pageName={listPageName}
		entities={entityName}
		orderBy={'internalName'}
		rendererProps={{
			title: listingTitle,
			actions: <PageLinkButton to={createPageName}>{addNewTitle}</PageLinkButton>,
		}}
	>
		{grid}
		<TableCell shrunk>
			<EditButton pageName={editPageName} />
		</TableCell>
	</TablePage>
)
