import { Component, FormGroupProps, HasOne, Repeater, SelectField } from '@contember/admin'
import * as React from 'react'

export interface DocumentListFieldProps {
	field: string
	label?: FormGroupProps['label']
}

export const DocumentListField = Component<DocumentListFieldProps>(props => (
	<HasOne field={props.field}>
		<Repeater field="items" sortableBy="order" label={props.label}>
			<SelectField
				field="document"
				label="Dokument"
				options={{
					fields: 'Document.internalName',
					orderBy: 'internalName asc',
				}}
			/>
		</Repeater>
	</HasOne>
))
