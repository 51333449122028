import {
	Component,
	Field,
	FormGroup,
	FormGroupProps,
	HasOne,
	Repeater,
	TextInput,
	useRelativeSingleField,
} from '@contember/admin'
import * as React from 'react'

export interface YoutubeVideoListFieldProps {
	field: string
	label?: FormGroupProps['label']
}

export const YoutubeVideoPreview = Component<{ videoId: string }>(({ videoId }) => (
	<div
		style={{
			paddingTop: '56.25%',
			position: 'relative',
		}}
	>
		<iframe
			style={{
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
			}}
			width="560"
			height="315"
			src={`https://www.youtube.com/embed/${videoId}`}
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
		/>
	</div>
))

export const YoutubeVideoField = Component<{ field: string }>(
	({ field }) => {
		const idField = useRelativeSingleField<string>(`${field}.videoId`)
		const fieldValue = idField.currentValue
		const [inputValue, setInputValue] = React.useState('')

		const onChange = (event: any) => {
			const newValue = event.target.value
			setInputValue(newValue)

			const id = (() => {
				try {
					const url = new URL(newValue)
					if (url.host.endsWith('youtube.com')) {
						return url.searchParams.get('v') || undefined
					} else if (url.host.endsWith('youtu.be')) {
						return url.pathname.substr(1)
					}
				} catch {}
			})()

			if (id) {
				idField.updateValue(id)
			}
		}

		if (fieldValue) {
			return <YoutubeVideoPreview videoId={fieldValue} />
		}

		return (
			<FormGroup label="YouTube video URL">
				<TextInput value={inputValue} onChange={onChange} />
			</FormGroup>
		)
	},
	({ field }) => <Field field={`${field}.videoId`} />,
	'YoutubeVideoField',
)

export const YoutubeVideoListField = Component<YoutubeVideoListFieldProps>(props => (
	<HasOne field={props.field}>
		<Repeater field="items" sortableBy="order" label={props.label}>
			<YoutubeVideoField field="youtube" />
		</Repeater>
	</HasOne>
))
