import { referenceCategoryRoutes } from './ReferenceCategoryPages'
import { referenceItselfRoutes } from './ReferencePages'

export * from './ReferenceCategoryPages'
export * from './ReferencePages'
export * from './ReferencePageNames'

export const referenceRoutes = {
	...referenceCategoryRoutes,
	...referenceItselfRoutes,
}
