import { AltPersistButton, MultiEditPage, TextField } from '@contember/admin'
import * as React from 'react'
import { LocaleSideDimension } from '../../components'
import { helpTopicPageName } from './HelpPageNames'

export const helpTopicRoutes = {
	[helpTopicPageName]: { path: '/help/topics' },
}

export const HelpTopicOrderPage = (
	<MultiEditPage
		entities="HelpTopic"
		pageName={helpTopicPageName}
		rendererProps={{
			title: 'Témata poradny',
			sortableBy: 'order',
			persistButtonComponent: AltPersistButton,
		}}
	>
		<TextField label="Interní název" field="name" />
		<LocaleSideDimension>
			<TextField label="Název" field="name" />
		</LocaleSideDimension>
	</MultiEditPage>
)
