import {
	AltPersistButton,
	Component,
	CreatePage,
	EditPage,
	Field,
	MultiSelectField,
	NavigateBackButton,
	PageLinkButton,
	SelectField,
	TableCell,
	TablePage,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import {
	EditButton,
	ImageField,
	ImagePreviewField,
	SelectProductColorField,
	VariantRelationInfoField,
} from '../../components'
import { productSwatchCreatePageName, productSwatchEditPageName, productSwatchListPageName } from './ProductPageNames'

export const productSwatchRoutes = {
	[productSwatchListPageName]: { path: '/product/swatches' },
	[productSwatchCreatePageName]: { path: '/product/swatches/new' },
	[productSwatchEditPageName]: { path: '/product/swatches/:id' },
}

const listPageName = productSwatchListPageName
const createPageName = productSwatchCreatePageName
const editPageName = productSwatchEditPageName
const entityName = 'ProductSwatch'
const addNewTitle = 'Přidat vzorník produktu'
const listingTitle = 'Vzorníky produktů'
const editTitle = 'Upravit vzorník produktu'

const grid = (
	<>
		<TableCell shrunk>
			<ImagePreviewField srcField="image.url" standalone />
		</TableCell>
		<TableCell>
			<Field field={'internalName'} />
		</TableCell>
		<TableCell>
			<Field field={'color.internalName'} format={value => value || 'BEZ BARVY'} />
		</TableCell>
		<TableCell>
			<Field field={'surface.internalName'} />
		</TableCell>
		<TableCell>
			<VariantRelationInfoField />
		</TableCell>
	</>
)
export const ProductSwatchForm = Component(
	() => (
		<>
			<TextField field="internalName" label="Interní název" />
			<ImageField field={'image'} label={'Obrázek'} allowImageDisconnect />
			<SelectProductColorField field="color" />
			<SelectField field="surface" label={'Povrch'} options={'ProductSurface.internalName'} />
			<MultiSelectField field={'variants'} label={'Varianty'} options={'ProductVariant.internalName'} />
		</>
	),
	'ProductSwatchForm',
)

export const ProductSwatchCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
	>
		<ProductSwatchForm />
	</CreatePage>
)
export const ProductSwatchEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<ProductSwatchForm />
	</EditPage>
)

export const ProductSwatchListPage = (
	<TablePage
		pageName={listPageName}
		entities={entityName}
		orderBy={'internalName'}
		rendererProps={{
			title: listingTitle,
			actions: <PageLinkButton to={createPageName}>{addNewTitle}</PageLinkButton>,
		}}
	>
		{grid}
		<TableCell shrunk>
			<EditButton pageName={editPageName} />
		</TableCell>
	</TablePage>
)
