import { helpQuestionRoutes } from './HelpQuestionPages'
import { helpTopicRoutes } from './HelpTopicPages'

export * from './HelpQuestionPages'
export * from './HelpTopicPages'
export * from './HelpPageNames'
export const helpRoutes = {
	...helpQuestionRoutes,
	...helpTopicRoutes,
}
