import { ProjectConfig } from '@contember/admin'

const routes = {
	dashboard: { path: '/' },

	frontPage: { path: '/front-page' },
	footer: { path: '/footer' },

	blogPage: { path: '/stories/page' },
	blogList: { path: '/stories' },
	blogCreate: { path: '/stories/new' },
	blogEdit: { path: '/stories/:id' },

	pubsPage: { path: '/pubs/page' },
	pubList: { path: '/pubs' },
	pubCreate: { path: '/pubs/new' },
	pubEdit: { path: '/pubs/:id' },

	podcastList: { path: '/podcasts' },
	podcastCreate: { path: '/podcasts/new' },
	podcastEdit: { path: '/podcasts/:id' },

	tapstersPage: { path: '/tapsters/page' },
	tapsterList: { path: '/tapsters' },
	tapsterCreate: { path: '/tapsters/new' },
	tapsterEdit: { path: '/tapsters/:id' },

	pageList: { path: '/pages' },
	pageCreate: { path: '/page/create' },
	pageEdit: { path: '/page/:id' },

	pour: { path: '/pour' },

	hops: { path: '/hops' },

	menu: { path: '/menu' },
	categories: { path: '/categories' },
	categoryCreate: { path: '/categories/new' },
	categoryEdit: { path: '/categories/:id' },
	tags: { path: '/tags' },
	tagCreate: { path: '/tags/new' },
	tagEdit: { path: '/tags/:id' },
	locations: { path: '/locations' },
	locationCreate: { path: '/locations/new' },
	locationEdit: { path: '/locations/:id' },
	social: { path: '/social-networks' },

	sites: { path: '/sites' },
	translations: { path: '/translations' },

	// tenant
	tenantChangePassword: { path: '/change-password' },
	tenantUsers: { path: '/users' },
	tenantInviteUser: { path: '/invite-user' },
	tenantEditUser: { path: '/edit-user/:id' },
}
const config: ProjectConfig[] = [
	{
		project: 'pilsner',
		stage: 'prod',
		component: () => import('./prod'),
		defaultDimensions: {
			site: ['en'],
		},
		routes: routes,
	},
	{
		project: 'pilsner-beta',
		stage: 'prod',
		component: () => import('./beta'),
		defaultDimensions: {
			site: ['en'],
		},
		routes: routes,
	},
]

export default config
