import { ProjectConfig } from '@contember/admin'

const routes = {
	dashboard: { path: '/' },

	categories: { path: '/categories' },
	categoryCreate: { path: '/categories/new' },
	categoryEdit: { path: '/categories/:id' },
	headerMenu: { path: '/headermenu' },
	homePage: { path: '/homepage' },
	footerMenu: { path: '/footermenu' },
	products: { path: '/products' },
	productCreate: { path: '/products/new' },
	productEdit: { path: '/products/:id' },

	// tenant
	tenantChangePassword: { path: '/change-password' },
	tenantUsers: { path: '/users' },
	tenantInviteUser: { path: '/invite-user' },
	tenantEditUser: { path: '/edit-user/:id' },
}
const config: ProjectConfig[] = [
	{
		project: 'lovechub',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
