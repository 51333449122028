import {
	AltPersistButton,
	Box,
	Component,
	CreatePage,
	EditPage,
	Field,
	NavigateBackButton,
	PageLinkButton,
	TableCell,
	TablePage,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { ContentField, EditButton, LinkUrlField, LocaleSideDimension, SeoForm } from '../../components'
import { productUsageCreatePageName, productUsageEditPageName, productUsageListPageName } from './ProductPageNames'

export const productUsageRoutes = {
	[productUsageListPageName]: { path: '/product/usagees' },
	[productUsageCreatePageName]: { path: '/product/usagees/new' },
	[productUsageEditPageName]: { path: '/product/usagees/:id' },
}

const listPageName = productUsageListPageName
const createPageName = productUsageCreatePageName
const editPageName = productUsageEditPageName
const entityName = 'ProductUsage'
const addNewTitle = 'Přidat použití produktu'
const listingTitle = 'Použití produktů'
const editTitle = 'Upravit použití produktu'

const grid = (
	<>
		<TableCell>
			<Field field={'internalName'} />
		</TableCell>
	</>
)
export const ProductUsageForm = Component(
	() => (
		<>
			<TextField field="internalName" label="Interní název" />
			<LinkUrlField derivedFrom="internalName" label="URL" persistedSoftPrefix="produkty-pouziti/" />
			<LocaleSideDimension>
				<Box>
					<TextField field="name" label="Název" />
				</Box>
				<ContentField field="content" />
				<SeoForm titleDerivedFrom="name" />
			</LocaleSideDimension>
		</>
	),
	'ProductUsageForm',
)

export const ProductUsageCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
	>
		<ProductUsageForm />
	</CreatePage>
)
export const ProductUsageEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<ProductUsageForm />
	</EditPage>
)

export const ProductUsageListPage = (
	<TablePage
		pageName={listPageName}
		entities={entityName}
		orderBy={'internalName'}
		rendererProps={{
			title: listingTitle,
			actions: <PageLinkButton to={createPageName}>{addNewTitle}</PageLinkButton>,
		}}
	>
		{grid}
		<TableCell shrunk>
			<EditButton pageName={editPageName} />
		</TableCell>
	</TablePage>
)
