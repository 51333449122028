import {
	AltPersistButton,
	Box,
	Component,
	CreatePage,
	EditPage,
	Field,
	Literal,
	NavigateBackButton,
	PageLinkButton,
	SelectField,
	TableCell,
	TablePage,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { ContentField, EditButton, LinkUrlField, LocaleSideDimension, SeoForm } from '../../components'

export const pageListPageName = 'pageList'
export const pageCreatePageName = 'pageCreate'
export const pageEditPageName = 'pageEdit'

export const pageRoutes = {
	[pageListPageName]: { path: '/pages' },
	[pageCreatePageName]: { path: '/pages/new' },
	[pageEditPageName]: { path: '/pages/:id' },
}

const listPageName = pageListPageName
const createPageName = pageCreatePageName
const editPageName = pageEditPageName
const entityName = 'Page'
const addNewTitle = 'Přidat novou stránku'
const listingTitle = 'Stránky'
const editTitle = 'Upravit stránku'
const PageForm = Component(
	() => (
		<>
			<TextField field="internalName" label="Interní název" />
			<LinkUrlField derivedFrom="internalName" label="URL" persistedSoftPrefix="" />
			<SelectField
				label="Speciální stránka"
				allowNull={true}
				options={[
					{ value: new Literal('homepage'), label: 'Úvodní stránka' },
					{ value: new Literal('help'), label: 'Poradna' },
					{ value: new Literal('blog'), label: 'Magazín' },
					{ value: new Literal('contactSalesRepresentatives'), label: 'Kontakty - obchodní zástupci' },
					{ value: new Literal('contactInfo'), label: 'Kontakty - základní info' },
					{ value: new Literal('contactMap'), label: 'Kontakty - mapa' },
					{ value: new Literal('shoppingList'), label: 'Nákupní seznam' },
					{ value: new Literal('shoppingListInquiry'), label: 'Nákupní seznam - odeslání poptávky' },
					{ value: new Literal('shoppingListShare'), label: 'Nákupní seznam - sdílení poptávky' },
					{ value: new Literal('downloads'), label: 'Ke stažení' },
					{ value: new Literal('career'), label: 'Kariéra' },
					{ value: new Literal('searchResults'), label: 'Výsledky hledání' },
					{ value: new Literal('representatives'), label: 'Reference' },
				]}
				field="specialPage"
			/>

			<LocaleSideDimension>
				<Box>
					<TextField field="title" label="Titulek" size="large" />
				</Box>
				<ContentField field="content" />
				<SeoForm titleDerivedFrom="title" />
			</LocaleSideDimension>
		</>
	),
	'PageForm',
)
const grid = (
	<TableCell>
		<Field field="internalName" />
	</TableCell>
)

export const PageCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
	>
		<PageForm />
	</CreatePage>
)
export const PageEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<PageForm />
	</EditPage>
)

export const PageListPage = (
	<TablePage
		pageName={listPageName}
		entities={entityName}
		rendererProps={{
			title: listingTitle,
			actions: <PageLinkButton to={createPageName}>{addNewTitle}</PageLinkButton>,
		}}
	>
		{grid}
		<TableCell shrunk>
			<EditButton pageName={editPageName} />
		</TableCell>
	</TablePage>
)
