import { documentItselfRoutes } from './DocumentPages'
import { documentCategoryRoutes } from './DocumentCategoryPages'

export * from './DocumentPages'
export * from './DocumentPageNames'
export * from './DocumentCategoryPages'

export const documentRoutes = {
	...documentItselfRoutes,
	...documentCategoryRoutes,
}
