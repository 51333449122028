import { DateFieldView, Field, TableCell, TablePage } from '@contember/admin'
import * as React from 'react'
import { contactFormListPageName } from './ContactPageNames'

export const contactFormRoutes = {
	[contactFormListPageName]: { path: '/contact/form' },
}

export const ContactFormListPage = (
	<TablePage
		pageName={contactFormListPageName}
		entities="ContactMessage"
		orderBy="createdAt desc"
		rendererProps={{
			title: 'Formuláře',
			enableRemove: false,
		}}
	>
		<TableCell shrunk justification="justifyEnd">
			<DateFieldView field="createdAt" />
		</TableCell>
		<TableCell shrunk>
			<b>
				<Field field="name" />
			</b>
		</TableCell>
		<TableCell shrunk>
			<Field field="email" />
		</TableCell>
		<TableCell shrunk justification="justifyEnd">
			<Field field="phone" />
		</TableCell>
		<TableCell shrunk>
			<Field field="place" />
		</TableCell>
		<TableCell>
			<Field field="message" />
		</TableCell>
	</TablePage>
)
