import {
	AltPersistButton,
	Box,
	CheckboxField,
	Component,
	DateField,
	EditPage,
	Field,
	FloatField,
	HasMany,
	HasOne,
	MultiSelectField,
	NavigateBackButton,
	NumberField,
	Repeater,
	SelectField,
	TableCell,
	TablePage,
	TextAreaField,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import {
	CentsField,
	DocumentListField,
	EditButton,
	ImageField,
	ImageListField,
	ImagePreview,
	ImagePreviewField,
	IsPublishedField,
	LinkUrlField,
	LocaleSideDimension,
	ReadonlyCheckboxField,
	SeoForm,
} from '../../components'
import { YoutubeVideoListField } from '../../components/YoutubeVideoListField'
import { createReindexCallback } from '../../utils/createReindexCallback'
import { productEditPageName, productListPageName } from './ProductPageNames'

export const productItselfRoutes = {
	[productListPageName]: { path: '/products' },
	[productEditPageName]: { path: '/products/:id' },
}

const listPageName = productListPageName
const editPageName = productEditPageName
const entityName = 'Product'
const listingTitle = 'Produkty'
const editTitle = 'Upravit produkt'
const grid = (
	<>
		<TableCell shrunk>
			<ImagePreviewField srcField="listingImage.url" standalone />
		</TableCell>
		<TableCell>
			<Field field="internalName" />
		</TableCell>
	</>
)

export const ProductForm = Component(
	() => (
		<>
			<TextField field="internalName" label="Interní název" />
			<TextField field="externalIdentifier" label="Externí identifikátor" readOnly={true} />
			<LinkUrlField label="URL" derivedFrom="internalName" />
			<ImageField field="listingImage" label="Obrázek pro výpisy" allowImageDisconnect />
			<ImageListField field="images" label="Obrázky" />
			<DocumentListField field="documents" label="Dokumenty" />
			<YoutubeVideoListField field="youtubeVideos" label="YouTube" />
			<CheckboxField field="newProduct" label="Novinka" />
			<MultiSelectField field="categories" label="Kategorie" options="ProductCategory.internalName" />
			<MultiSelectField field="usages" label="Použití" options="ProductUsage.internalName" />
			<LocaleSideDimension>
				<TextField field="name" label="Název" readOnly={true} />
				<TextAreaField field="description" label="Popis" />
				<TextField field="altName" label="Alternativní název" readOnly={true} />
				<TextField field="note" label="Poznámka" readOnly={true} />
				<TextField field="additionalSize" label="Doplňková velikost" readOnly={true} />
				<TextField field="shortSize" label="Krátká velikost pro výpis" />
				<SeoForm titleDerivedFrom="name" />
			</LocaleSideDimension>
			<Box heading="Atributy">
				<HasOne field="attributes">
					<NumberField readOnly={true} field="length" label="Délka" />
					<NumberField readOnly={true} field="width" label="Šířka" />
					<NumberField readOnly={true} field="height" label="Výška" />
					<TextField readOnly={true} field="unit" label="Jednotka" />
					<ReadonlyCheckboxField field="walkable" label="Pochozí" />
					<ReadonlyCheckboxField field="rideableByCar" label="Pojízdné autem" />
					<ReadonlyCheckboxField field="rideableByTruck" label="Pojízdné náklaďákem" />
					<ReadonlyCheckboxField field="frostResistant" label="Mrazuvzdorné" />
					<ReadonlyCheckboxField field="antiSlip" label="Protiskluzové" />
					<ReadonlyCheckboxField field="withoutChamfer" label="Bez fazety" />
					<ReadonlyCheckboxField field="visibleEdge" label="Pohledové hrany" />
					<ReadonlyCheckboxField field="customMade" label="Zakázková výroba" />
				</HasOne>
			</Box>
			<Repeater field="variants" label="Varianty" enableAddingNew={false} enableRemoving={false}>
				<TextField field="internalName" label="Interní název" />
				<TextField field="externalIdentifier" label="Externí identifikátor" readOnly={true} />
				<DateField field="publishedAt" label="Datum publikace" />
				<ImageListField field="images" label="Obrázky" />
				<TextField field="color.internalName" label="Barva" readOnly={true} />
				<TextField field="surface.internalName" label="Povrch" readOnly={true} />
				<SelectField
					field="swatch"
					label={'Vzorník'}
					searchByFields={'internalName'}
					options={{
						entityName: 'ProductSwatch',
						orderBy: 'internalName',
					}}
					renderOption={accessor => {
						const text = accessor.getField<string>('internalName').currentValue
						const imageUrl = accessor.getField<string>('image.url').currentValue
						const variants = accessor.getEntityList('variants')
						const variantNames = Array.from(
							variants,
							locale => locale.getField('product.internalName').currentValue,
						).join(', ')
						return (
							<span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '100%' }}>
								<ImagePreview src={imageUrl} />
								{text} <span style={{ fontSize: '10px', color: '#777' }}>{variantNames}</span>
							</span>
						)
					}}
					optionsStaticRender={
						<>
							<Field field="internalName" />
							<Field field="image.url" />
							<HasMany field="variants">
								<Field field="product.internalName" />
							</HasMany>
						</>
					}
					allowNull={true}
				/>
				<ReadonlyCheckboxField field="newVariant" label="Novinka" />
				<CheckboxField field="discounted" label="Slevněno" />
				<Box heading="Atributy">
					<HasOne field="attributes">
						<FloatField readOnly={true} field="quantityPerSqm" label="Kusy na ㎡" />
						<FloatField readOnly={true} field="weightPerSqm" label="Váha na ㎡" />
						<FloatField readOnly={true} field="squareMeterPerPallet" label="㎡ na paletě" />
						<FloatField readOnly={true} field="palletWeight" label="Váha palety" />
						<TextField readOnly={true} field="palletType" label="Typ palety" />
						<TextField readOnly={true} field="palletLayerQuantity" label="Kusy ve vrstvě palety" />
						<TextField readOnly={true} field="palletLayerSqm" label="㎡ ve vrstvě palety" />
						<TextField readOnly={true} field="impregnation" label="Impregnace" />
					</HasOne>
				</Box>
				<Repeater field="prices" label="Ceny" enableAddingNew={false} enableRemoving={false}>
					<CentsField field="cents" label="Hodnota" readOnly={true} />
					<TextField readOnly={true} field="currency" label="Měna" />
				</Repeater>
			</Repeater>
		</>
	),
	'ProductForm',
)

export const ProductEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		onBeforePersist={createReindexCallback('reindexProduct')}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			side: <IsPublishedField />,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<ProductForm />
	</EditPage>
)

export const ProductListPage = (
	<TablePage
		pageName={listPageName}
		entities={entityName}
		orderBy="internalName"
		rendererProps={{
			title: listingTitle,
		}}
	>
		{grid}
		<TableCell shrunk>
			<EditButton pageName={editPageName} />
		</TableCell>
	</TablePage>
)
