import {
	Checkbox,
	Component,
	Field,
	SimpleRelativeSingleFieldMetadata,
	SimpleRelativeSingleFieldProps,
	SimpleRelativeSingleFieldProxy,
} from '@contember/admin'
import * as React from 'react'

export interface ReadonlyCheckboxField extends SimpleRelativeSingleFieldProps {}

export const ReadonlyCheckboxField = Component<ReadonlyCheckboxField>(
	props => (
		<SimpleRelativeSingleFieldProxy
			{...props}
			render={(fieldMetadata: SimpleRelativeSingleFieldMetadata<boolean>, props) => {
				return (
					<Checkbox
						checked={!!fieldMetadata.field.currentValue}
						onChange={() => null}
						readOnly={true}
						errors={fieldMetadata.field.errors}
					>
						{fieldMetadata.environment.applySystemMiddleware('labelMiddleware', props.label)}
					</Checkbox>
				)
			}}
		/>
	),
	props => <Field field={props.field} />,
)
