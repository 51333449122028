import { MultiEditPage, TextField, AltPersistButton } from '@contember/admin'
import * as React from 'react'

export const localePageName = 'locale'

export const localeRoutes = {
	[localePageName]: { path: '/settings/locales' },
}

export const LocalePage = (
	<MultiEditPage
		entities="Locale"
		pageName={localePageName}
		rendererProps={{
			title: 'Jazyky',
			persistButtonComponent: AltPersistButton,
		}}
	>
		<TextField label="Kód" field="code" />
		<TextField label="Název" field="caption" />
	</MultiEditPage>
)
