import { Component, Field } from '@contember/admin'
import Snappyimg from '@mangoweb/snappyimg'
import * as React from 'react'
import { buildSnappyimgUrl } from '../utils'

interface ImagePreviewFieldProps extends Omit<ImagePreviewProps, 'src'> {
	srcField: string
}
export const ImagePreviewField = Component<ImagePreviewFieldProps>(({ srcField, ...props }) => (
	<Field field={srcField} format={value => <ImagePreview src={value?.toString()} {...props} />} />
))

interface ImagePreviewProps {
	src: string | null | undefined
	circle?: boolean
	standalone?: boolean
}

export const ImagePreview = Component<ImagePreviewProps>(({ src, circle, standalone }) => {
	const snappyimgUrl = React.useMemo(
		() =>
			src &&
			buildSnappyimgUrl(src, {
				resize: Snappyimg.Resize.Fill,
				width: circle ? 54 : 96,
				height: 54,
			}),
		[src, circle],
	)
	return (
		<span
			style={{
				position: 'relative',
				display: 'inline-block',
				width: '3em',
				height: '1.7em',
				backgroundColor: 'rgb(229, 229, 236)',
				verticalAlign: 'middle',
				overflow: 'hidden',
				...(circle
					? {
							borderRadius: '50%',
							width: '1.7em',
					  }
					: {}),
				...(standalone
					? {}
					: {
							marginRight: '0.7em',
					  }),
			}}
		>
			{snappyimgUrl && (
				<img
					src={snappyimgUrl}
					alt=""
					style={{ objectFit: 'cover', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
					loading="lazy"
				/>
			)}
		</span>
	)
})
