import { AltPersistButton, MultiEditPage, TextField } from '@contember/admin'
import * as React from 'react'
import { EditButton } from '../../components'
import {
	contactCompanyListPageName,
	contactCountryListPageName,
	contactDepartmentListPageName,
	contactOfficeListPageName,
	contactRegionListPageName,
} from './ContactPageNames'

export const contactCountryRoutes = {
	[contactCountryListPageName]: { path: '/contact/countries' },
}

export const ContactCountryListPage = (
	<MultiEditPage
		pageName={contactCountryListPageName}
		entities="ContactCountry"
		orderBy="code desc"
		rendererProps={{
			title: 'Země',
			persistButtonComponent: AltPersistButton,
		}}
	>
		<TextField field="code" label={'Code'} />
		<br />
		<EditButton pageName={contactRegionListPageName}>Regiony</EditButton>{' '}
		<EditButton pageName={contactDepartmentListPageName}>Oddělení</EditButton>{' '}
		<EditButton pageName={contactCompanyListPageName}>Společnost</EditButton>{' '}
		<EditButton pageName={contactOfficeListPageName}>Obchodní informace</EditButton>
	</MultiEditPage>
)
