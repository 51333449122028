import {
	ChangePassword,
	EditUserInProject,
	GenericPage,
	InviteUserToProject,
	LayoutInner,
	Page,
	RolesConfig,
	UsersManagement,
} from '@contember/admin'
import * as React from 'react'

export const tenantChangePasswordPageName = 'tenantChangePassword'
export const tenantUsersPageName = 'tenantUsers'
export const tenantInviteUserPageName = 'tenantInviteUser'
export const tenantEditUserPageName = 'tenantEditUser'

export const tenantRoutes = {
	[tenantChangePasswordPageName]: { path: '/change-password' },
	[tenantUsersPageName]: { path: '/users' },
	[tenantInviteUserPageName]: { path: '/invite-user' },
	[tenantEditUserPageName]: { path: '/edit-user/:id' },
}

const rolesConfig: RolesConfig = {
	admin: {
		name: 'Administrator',
		variables: {},
	},
}

export const ChangePasswordPage = (
	<GenericPage pageName={tenantChangePasswordPageName}>
		<ChangePassword />
	</GenericPage>
)

// query is required, so send a dummy query
export const UsersManagementPage = (
	<GenericPage pageName={tenantUsersPageName}>
		<UsersManagement
			rolesDataQuery={`
query {
  _info {
    description
  }
}
				`}
			roleRenderers={{
				admin: () => <>Administrator</>,
			}}
		/>
	</GenericPage>
)
export const InviteUserPage = (
	<GenericPage pageName={tenantInviteUserPageName}>
		<InviteUserToProject rolesConfig={rolesConfig} />
	</GenericPage>
)
export const EditUserPage = (
	<Page<{ tenantEditUser: { id: string } }> name={tenantEditUserPageName}>
		{({ id }) => (
			<LayoutInner>
				<EditUserInProject rolesConfig={rolesConfig} identityId={id} />
			</LayoutInner>
		)}
	</Page>
)
