import { AltPersistButton, MultiEditPage, NavigateBackButton, SelectField, TextField } from '@contember/admin'
import * as React from 'react'
import { contactCountryListPageName, contactRegionListPageName } from './ContactPageNames'

export const contactRegionRoutes = {
	[contactRegionListPageName]: { path: '/contact/regions/:id' },
}

export const ContactRegionListPage = (
	<MultiEditPage
		pageName={contactRegionListPageName}
		entities="ContactRegion[country.id = $id]"
		setOnCreate="(country.id = $id)"
		orderBy="code desc"
		rendererProps={{
			title: 'Regiony',
			navigation: <NavigateBackButton to={contactCountryListPageName}>Země</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<TextField field="code" label="Kód" description="LAU 1" />
		<TextField field="name" label="Název" />
		<SelectField label="Sales representative" options="ContactPerson.name" field="salesRepresentative" />
	</MultiEditPage>
)
