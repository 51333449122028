import { Component, FormGroupProps, SlugField, SlugFieldProps } from '@contember/admin'
import * as React from 'react'

export interface LinkUrlFieldProps extends Partial<SlugFieldProps> {
	derivedFrom: SlugFieldProps['derivedFrom']
	softPrefix?: string
	label: FormGroupProps['label']
}

export const LinkUrlField = Component<LinkUrlFieldProps>(
	({ softPrefix, derivedFrom, label, ...props }) => (
		<SlugField
			field="link.url"
			derivedFrom={derivedFrom}
			label={label}
			unpersistedHardPrefix={environment => environment.getValue('PRESBETON_WEB_URL')}
			persistedHardPrefix="/"
			persistedSoftPrefix={softPrefix}
			{...props}
		/>
	),
	'LinkUrlField',
)
