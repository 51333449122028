import { ProjectConfig } from '@contember/admin'

export const pages = [
	{ key: 'restaurantMenuPage', path: '/', label: 'Jídelní lístek' },
	{ key: 'openingHoursPage', path: '/opening-hours', label: 'Otevírací hodiny' },
	{ key: 'homepagePage', path: '/homepagePage', label: 'Home' },
	{ key: 'accomodationPage', path: '/accomodationPage', label: 'Ubytování' },
	{ key: 'contactPage', path: '/contactPage', label: 'Kontakt' },
	{ key: 'photoStripPage', path: '/photoStripPage', label: 'Proužek fotek' },
	{ key: 'photoGridPage', path: '/photoGridPage', label: 'Mřížka fotek' },
] as const

const routes: Record<string, { path: string }> = pages.reduce((routes, page) => {
	return { ...routes, [page.key]: { path: page.path } }
}, {})

routes.dashboard = { path: '/' }

const config: ProjectConfig[] = [
	{
		project: 'abeluvmlyn',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
