import { ProjectConfig } from '@contember/admin'
import { translationRoutes } from '../../../plugins/translations/admin/routes'

const routes = {
	dashboard: { path: '/' },

	tldrList: { path: '/tldr' },
	tldrCreate: { path: '/tldr/new' },
	tldrCreateWithUrl: { path: '/tldr/new/:url' },
	tldrEdit: { path: '/tldr/:id' },

	requestList: { path: '/request' },
	requestEdit: { path: '/request/:id' },

	userList: { path: '/user' },
	userDetail: { path: '/user/:id' },
	userAddCreditPage: { path: '/user/:id/addCredit' },

	groupsEditPage: { path: '/group' },

	...translationRoutes(),
}
const config: ProjectConfig[] = [
	{
		project: 'tldr',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
