import { ProjectConfig } from '@contember/admin'

const routes = {
	dashboard: { path: '/' },

	pageList: { path: '/pages' },
	pageCreate: { path: '/pages/new' },
	pageEdit: { path: '/pages/:id' },
	postList: { path: '/posts' },
	submissionEdit: { path: '/submissions/:id' },
	submissionList: { path: '/submissions' },
	subscriberEdit: { path: '/subscribers/:id' },
	subscriberList: { path: '/subscribers' },
	postCreate: { path: '/posts/new' },
	postEdit: { path: '/posts/:id' },
	flatList: { path: '/flats' },
	flatCreate: { path: '/flats/new' },
	flatEdit: { path: '/flats/:id' },
	footerPage: { path: '/footer/' },
	headerMenu: { path: '/menu/' },
}
const config: ProjectConfig[] = [
	{
		project: 'vrchlabi',
		stage: 'live',
		component: () => import('./live'),
		routes: routes,
	},
]

export default config
