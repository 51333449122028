import { AltPersistButton, MultiEditPage, NavigateBackButton, Repeater, SelectField, TextField } from '@contember/admin'
import * as React from 'react'
import { LocaleSideDimension } from '../../components'
import { contactCountryListPageName, contactDepartmentListPageName } from './ContactPageNames'

export const contactDepartmentRoutes = {
	[contactDepartmentListPageName]: { path: '/contact/departments/:id' },
}

export const ContactDepartmentListPage = (
	<MultiEditPage
		pageName={contactDepartmentListPageName}
		entities="ContactDepartment[country.id = $id]"
		setOnCreate="(country.id = $id)"
		rendererProps={{
			title: 'Oddělení',
			sortableBy: 'order',
			navigation: <NavigateBackButton to={contactCountryListPageName}>Země</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<LocaleSideDimension>
			<TextField field="name" label="Název" />
		</LocaleSideDimension>
		<Repeater field={'people'} sortableBy={'order'} removalType={'delete'} label={'Lidé'}>
			<SelectField label={'Osoba'} options={'ContactPerson.name'} field={'person'} />
		</Repeater>
	</MultiEditPage>
)
