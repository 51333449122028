import {
	AltPersistButton,
	Component,
	CreatePage,
	EditPage,
	Field,
	NavigateBackButton,
	PageLinkButton,
	TableCell,
	TablePage,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { EditButton, LocaleSideDimension } from '../../components'
import { contactPersonCreatePageName, contactPersonEditPageName, contactPersonListPageName } from './ContactPageNames'

export const contactPersonRoutes = {
	[contactPersonListPageName]: { path: '/contact/people' },
	[contactPersonCreatePageName]: { path: '/contact/people/new' },
	[contactPersonEditPageName]: { path: '/contact/people/:id' },
}

const listPageName = contactPersonListPageName
const createPageName = contactPersonCreatePageName
const editPageName = contactPersonEditPageName
const entityName = 'ContactPerson'
const addNewTitle = 'Přidat osobu'
const listingTitle = 'Lidé'
const editTitle = 'Upravit osobu'
const grid = (
	<>
		<TableCell>
			<Field field="name" />
		</TableCell>
		<TableCell>
			<LocaleSideDimension>
				<Field field="jobTitle" />
			</LocaleSideDimension>
		</TableCell>
	</>
)

export const ContactPersonForm = Component(
	() => (
		<>
			<TextField field="name" size="large" label="Jméno" />
			<TextField field="phone" label="Telefon" />
			<TextField field="email" label="E-mail" />
			<LocaleSideDimension>
				<TextField field="jobTitle" label="Pozice" />
			</LocaleSideDimension>
		</>
	),
	'ContactPersonForm',
)

export const ContactPersonCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
	>
		<ContactPersonForm />
	</CreatePage>
)
export const ContactPersonEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<ContactPersonForm />
	</EditPage>
)

export const ContactPersonListPage = (
	<TablePage
		pageName={listPageName}
		entities={entityName}
		rendererProps={{
			title: listingTitle,
			actions: <PageLinkButton to={createPageName}>{addNewTitle}</PageLinkButton>,
		}}
	>
		{grid}
		<TableCell shrunk>
			<EditButton pageName={editPageName} />
		</TableCell>
	</TablePage>
)
