import {
	AltPersistButton,
	Component,
	CreatePage,
	EditPage,
	Field,
	NavigateBackButton,
	PageLinkButton,
	SelectField,
	TableCell,
	TablePage,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { EditButton, ImageListField, LocaleSideDimension } from '../../components'
import {
	representativeCreatePageName,
	representativeEditPageName,
	representativeListPageName,
} from './RepresentativePageNames'

export const representativeItselfRoutes = {
	[representativeListPageName]: { path: '/representatives' },
	[representativeCreatePageName]: { path: '/representatives/new' },
	[representativeEditPageName]: { path: '/representatives/:id' },
}

const listPageName = representativeListPageName
const createPageName = representativeCreatePageName
const editPageName = representativeEditPageName
const entityName = 'Representative'
const addNewTitle = 'Přidat referenci'
const listingTitle = 'Reference'
const editTitle = 'Upravit referenci'
const grid = (
	<>
		<TableCell>
			<Field field="internalName" />
		</TableCell>
	</>
)

export const RepresentativeForm = Component(
	() => (
		<>
			<TextField field="internalName" label="Interní název" />
			<SelectField field="category" allowNull label={undefined} options="RepresentativeCategory.internalName" />
			<LocaleSideDimension>
				<TextField field="title" label="Titulek" />
				<TextField field="note" label="Poznámka" />
			</LocaleSideDimension>
			<ImageListField field="images" label="Obrázky" />
		</>
	),
	'RepresentativeForm',
)

export const RepresentativeCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
	>
		<RepresentativeForm />
	</CreatePage>
)
export const RepresentativeEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<RepresentativeForm />
	</EditPage>
)

export const RepresentativeListPage = (
	<TablePage
		pageName={listPageName}
		entities={entityName}
		orderBy={'internalName'}
		rendererProps={{
			title: listingTitle,
			actions: <PageLinkButton to={createPageName}>{addNewTitle}</PageLinkButton>,
		}}
	>
		{grid}
		<TableCell shrunk>
			<EditButton pageName={editPageName} />
		</TableCell>
	</TablePage>
)
