import { ProjectConfig } from '@contember/admin'

const routes = {
	dashboard: { path: '/' },

	frontPage: { path: '/frontpage' },
	genericPageList: { path: '/genericpage-list' },
	genericPageCreate: { path: '/genericpage-create' },
	genericPageEdit: { path: '/genericpage-edit/:id' },
	programPlaceList: { path: '/programplace-list' },
	programTrackEdit: { path: '/programtrack-edit' },
	weeklyProgramPlaceList: { path: '/weeklyProgramPlace-list' },
	weeklyProgramPlaceEdit: { path: '/weeklyProgramPlace-edit' },
	weeklyProgramTrackEdit: { path: '/weeklyProgramTrack-edit' },
	weeklyProgramDayList: { path: '/weeklyProgramDayList' },

	headerMenu: { path: '/headerMenu' },
	footerMenu: { path: '/footerMenu' },

	// tenant
	tenantChangePassword: { path: '/change-password' },
	tenantUsers: { path: '/users' },
	tenantInviteUser: { path: '/invite-user' },
	tenantEditUser: { path: '/edit-user/:id' },
}
const config: ProjectConfig[] = [
	{
		project: 'korzonarodni',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
