import { Component, FormGroupProps, HasOne, Repeater, SelectField } from '@contember/admin'
import * as React from 'react'

export interface ProductListFieldProps {
	field: string
	label?: FormGroupProps['label']
}

export const ProductListField = Component<ProductListFieldProps>(props => (
	<HasOne field={props.field}>
		<Repeater field="items" sortableBy="order" label={props.label}>
			<SelectField field={'product'} label={'Produkt'} options={'Product.internalName'} />
		</Repeater>
	</HasOne>
))
