import {
	AltPersistButton,
	Component,
	CreatePage,
	EditPage,
	Field,
	NavigateBackButton,
	PageLinkButton,
	TableCell,
	TablePage,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { EditButton, LocaleSideDimension } from '../../components'
import {
	productSurfaceCreatePageName,
	productSurfaceEditPageName,
	productSurfaceListPageName,
} from './ProductPageNames'

export const productSurfaceRoutes = {
	[productSurfaceListPageName]: { path: '/product/surfaces' },
	[productSurfaceCreatePageName]: { path: '/product/surfaces/new' },
	[productSurfaceEditPageName]: { path: '/product/surfaces/:id' },
}

const listPageName = productSurfaceListPageName
const createPageName = productSurfaceCreatePageName
const editPageName = productSurfaceEditPageName
const entityName = 'ProductSurface'
const addNewTitle = 'Přidat povrch produktu'
const listingTitle = 'Povrchy produktu'
const editTitle = 'Upravit povrch produktu'
const grid = (
	<TableCell>
		<Field field={'internalName'} />
	</TableCell>
)
export const ProductSurfaceForm = Component(
	() => (
		<>
			<TextField field="internalName" label="Interní název" />
			<LocaleSideDimension>
				<TextField field="name" label="Název" />
			</LocaleSideDimension>
		</>
	),
	'ProductSurfaceForm',
)

export const ProductSurfaceCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
	>
		<ProductSurfaceForm />
	</CreatePage>
)
export const ProductSurfaceEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<ProductSurfaceForm />
	</EditPage>
)

export const ProductSurfaceListPage = (
	<TablePage
		pageName={listPageName}
		entities={entityName}
		orderBy={'internalName'}
		rendererProps={{
			title: listingTitle,
			actions: <PageLinkButton to={createPageName}>{addNewTitle}</PageLinkButton>,
		}}
	>
		{grid}
		<TableCell shrunk>
			<EditButton pageName={editPageName} />
		</TableCell>
	</TablePage>
)
