import { AltPersistButton, MultiEditPage, SelectField, TextField } from '@contember/admin'
import * as React from 'react'

export const RedirectsPage = (
	<MultiEditPage
		pageName="redirects"
		entities="Redirect"
		rendererProps={{
			title: 'Přesměrování',
			enableAddingNew: true,
			enableRemoving: true,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<TextField field="link.url" label="Původní adresa" defaultValue="/" />
		<SelectField field="target" label="Cílová adresa" options="Linkable.url" />
	</MultiEditPage>
)

export const redirectsPageName = 'redirects'

export const redirectsRoutes = {
	[redirectsPageName]: { path: '/redirects' },
}
