import { ProjectConfig } from '@contember/admin'
import { tenantRoutes } from '../../../plugins/tenant/admin/routes'
import { translationRoutes } from '../../../plugins/translations/admin/routes'

const routes = {
	dashboard: { path: '/' },

	// pages
	homePage: { path: '/homepage' },
	coursesPage: { path: '/coursespage' },
	contactPage: { path: '/contactpage' },
	weddingPage: { path: '/weddingpage' },
	privatePage: { path: '/privatepage' },
	faqPage: { path: '/faqpage' },
	voucherPage: { path: '/voucherpage' },
	termsAndConditionsPage: { path: '/termsandconditionspage' },

	// menus
	footerMenu: { path: '/footer-menu' },
	headerMenu: { path: '/header-menu' },

	// redirects
	redirects: { path: '/redirects' },

	// locales
	locales: { path: '/locales' },

	// Checkout
	courses: { path: '/courses' },

	createCourse: { path: '/course/create' },
	editCourse: {
		path: '/course/:id([0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12})',
	},
	addDates: { path: '/course/:id/add-dates' },

	vouchers: { path: '/vouchers' },
	voucherSeasons: { path: '/seasons' },

	...translationRoutes(),

	...tenantRoutes(),
}
const config: ProjectConfig[] = [
	{
		project: 'fuego',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
