import { ProjectConfig } from '@contember/admin'

export const routes: ProjectConfig['routes'] = {
	dashboard: { path: '/clients' },

	bindingList: { path: '/bindings' },
	bindingCreate: { path: '/bindings/new' },
	bindingEdit: { path: '/bindings/edit/:id' },

	clientCreate: { path: '/clients/new' },
	clientEdit: { path: '/clients/:slug' },

	logoManualList: { path: '/logo-manuals' },
	logoManualCreate: { path: '/logo-manuals/new' },
	logoManualEdit: { path: '/logo-manuals/:id' },

	fontList: { path: '/fonts' },
	fontCreate: { path: '/fonts/new' },
	fontEdit: { path: '/fonts/:id' },

	imageList: { path: '/images' },
	imageCreate: { path: '/images/new' },
	imageEdit: { path: '/images/:id' },

	materialList: { path: '/materials/:groupId' },
	materialCreate: { path: '/materials/:groupId/new' },
	materialEdit: { path: '/materials/:groupId/edit/:id' },

	materialGroupList: { path: '/material-groups' },
	materialGroupCreate: { path: '/material-groups/new' },
	materialGroupEdit: { path: '/material-groups/:id' },

	moduleList: { path: '/modules' },
	moduleCreate: { path: '/modules/new' },
	moduleEdit: { path: '/modules/:slug' },

	orderList: { path: '/orders' },
	orderEdit: { path: '/orders/:id' },

	printerList: { path: '/printers' },
	printerCreate: { path: '/printers/new' },
	printerEdit: { path: '/printers/:code' },

	templateGroupList: { path: '/template-groups' },
	templateGroupCreate: { path: '/template-groups/new' },
	templateGroupEdit: { path: '/template-groups/:id' },

	templateTagList: { path: '/template-tags' },
	templateTagCreate: { path: '/template-tags/new' },
	templateTagEdit: { path: '/template-tags/:id' },

	templateListGroupMembers: { path: '/templates-in-group/:groupId' },
	templateList: { path: '/templates' },
	templateCreate: { path: '/templates/new' },
	templateEdit: { path: '/templates/:id' },

	tenantChangePassword: { path: '/change-password' },
	tenantUsers: { path: '/users' },
	tenantInviteUser: { path: '/invite-user' },
	tenantEditUser: { path: '/edit-user/:id' },
}
