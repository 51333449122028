import {
	Block,
	BlockRepeater,
	CheckboxField,
	Component,
	DiscriminatedBlocks,
	FloatField,
	FormGroupProps,
	HasOne,
	Literal,
	Repeater,
	RichEditor,
	RichTextField,
	SelectField,
	TextAreaField,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { ArticleListField } from './ArticleListField'
import { DocumentListField } from './DocumentListField'
import { ImageField } from './ImageField'
import { ImageListField } from './ImageListField'
import { LinkField } from './LinkField'
import { ProductCategoryListField } from './ProductCategoryListField'
import { ProductListField } from './ProductListField'
import { YoutubeVideoField } from './YoutubeVideoListField'

const RB = RichEditor.buttons

export const contentBlocks = (
	<>
		<Block discriminateBy="leadText" label="Perex">
			<RichTextField field="jsonContent" label={undefined} />
		</Block>

		<Block discriminateBy="textWithImage" label="Text s obrázkem">
			<RichTextField field="jsonContent" label={undefined} />
			<ImageField field="image" label="Obrázek" />
		</Block>

		<Block discriminateBy="textColumns" label="Text ve sloupcích">
			<HasOne field="columnList">
				<Repeater field="items" label={undefined} sortableBy="order">
					<TextField field="primaryText" label="Nadpis" />
					<RichTextField field="jsonContent" label={undefined} />
				</Repeater>
			</HasOne>
		</Block>

		<Block discriminateBy="numberedColumns" label="Číslované sloupce">
			<TextField field="primaryText" label="Nadpis" />
			<HasOne field="columnList">
				<Repeater field="items" label={undefined} sortableBy="order">
					<RichTextField field="jsonContent" label={undefined} />
				</Repeater>
			</HasOne>
		</Block>

		<Block discriminateBy="linkBoxes" label="Boxy">
			<CheckboxField field="stretch" label="Široké" />
			<TextField field="primaryText" label="Nadpis" />
			<HasOne field="columnList">
				<Repeater field="items" label={undefined} sortableBy="order">
					<TextField field="primaryText" label="Nadpis" />
					<RichTextField field="jsonContent" label={undefined} />
					<ImageField field="image" label="Obrázek" allowImageDisconnect />
					<TextField field="buttonText" label="Text odkazu" />
					<DiscriminatedBlocks field="linkType" label="Obecný typ odkazu">
						<Block discriminateBy="link" label="Klasický odkaz">
							<LinkField field="buttonLink" />
						</Block>
						<Block discriminateBy="anchor" label="Odkaz na kategorii kariéry">
							<SelectField field="careerCategory" label="Kategorie" options="CareerCategory.name" allowNull />
						</Block>
					</DiscriminatedBlocks>
				</Repeater>
			</HasOne>
		</Block>

		<Block discriminateBy="highlightedBox" label="Zvýrazněný box">
			<RichTextField field="jsonContent" label={undefined} />
		</Block>

		<Block discriminateBy="articleList" label="Seznam článků">
			<ArticleListField field={'articleList'} />
		</Block>

		<Block discriminateBy="documentList" label="Seznam dokumentů">
			<DocumentListField field={'documentList'} />
		</Block>

		<Block discriminateBy="productList" label="Seznam produktů">
			<ProductListField field={'productList'} />
		</Block>

		<Block discriminateBy="categoryList" label="Seznam produktových kategorií">
			<ProductCategoryListField field={'categoryList'} />
		</Block>

		<Block discriminateBy="gallery" label="Galerie">
			<CheckboxField field="stretch" label="Široká" />
			<CheckboxField field="noCrop" label="Zachovat poměr stran v náhledu" />
			<CheckboxField field="noReveal" label="Bez prokliku do zvětšení" />
			<ImageListField field="gallery" label="Obrázky" />
		</Block>

		<Block discriminateBy="text" label="Text">
			<RichTextField
				field="jsonContent"
				label={undefined}
				inlineButtons={[
					[RB.bold, RB.italic, RB.underline, RB.anchor],
					[RB.headingOne, RB.headingTwo, RB.headingThree, RB.headingFour, RB.unorderedList, RB.orderedList],
					[RB.strikeThrough, RB.code],
				]}
			/>
		</Block>

		<Block discriminateBy="html" label="HTML">
			<TextAreaField field="primaryText" label={undefined} />
		</Block>

		<Block discriminateBy="leadText" label="Perex">
			<RichTextField field="jsonContent" label={undefined} />
		</Block>

		<Block discriminateBy="contactForm" label="Kontaktní formulář">
			<TextField field="primaryText" label="Nadpis" />
			<CheckboxField field="requireCity" label="Vyžadovat město a PSČ" />
			<RichTextField field="jsonContent" label={undefined} />
		</Block>

		<Block discriminateBy="homepageIntertwinedCategoryList" label="Úvodní stránka: dlaždice kategorií">
			<TextField field={'primaryText'} label={'Nadpis'} />
			<RichTextField field="jsonContent" label={undefined} />
			<HasOne field="columnList">
				<Repeater field="items" sortableBy="order" label="Položky">
					<TextField field="primaryText" label="Nadpis" />
					<LinkField field="buttonLink" />
					<ImageField field="image" label="Pozadí" />
				</Repeater>
			</HasOne>
		</Block>

		<Block discriminateBy="homepageCategorySwiper" label="Úvodní stránka: seznam kategorií 1">
			<TextField field={'primaryText'} label={'Nadpis'} />
			<ProductCategoryListField field={'categoryList'} />
			<RichTextField field="jsonContent" label={undefined} />
		</Block>

		<Block discriminateBy="homepageTopCategories" label="Úvodní stránka: seznam kategorií 2">
			<TextField field={'primaryText'} label={'Nadpis'} />
			<ProductCategoryListField field={'categoryList'} />
			<RichTextField field="jsonContent" label={undefined} />
		</Block>

		<Block discriminateBy="homepageNews" label="Úvodní stránka: novinky">
			<TextField field="primaryText" label="Nadpis" />
			<ArticleListField field="articleList" />
			<LinkField field="link" />
		</Block>

		<Block discriminateBy="homepageArticles" label="Úvodní stránka: články">
			<TextField field={'primaryText'} label={'Nadpis'} />
			<ArticleListField field={'articleList'} />
			<RichTextField field="jsonContent" label={undefined} />
		</Block>

		<Block discriminateBy="homepageInteractiveHero" label="Úvodní stránka: obrázek">
			<HasOne field="interactiveHero">
				<ImageField field="image" label="Grafika" />
				<Repeater field="items" label="Body">
					<TextField field="label" label="Název štítku" />
					<SelectField
						field="labelPosition"
						label="Pozice štítku"
						options={[
							{ value: new Literal('left'), label: 'Vlevo' },
							{ value: new Literal('right'), label: 'Vpravo' },
							{ value: new Literal('top'), label: 'Nahoře' },
							{ value: new Literal('bottom'), label: 'Dole' },
						]}
					/>
					<FloatField field="xPosition" label="X position" description="<0, 100>" />
					<FloatField field="yPosition" label="Y position" description="<0, 100>" />
					<LinkField field="link" />
				</Repeater>
			</HasOne>
		</Block>
		<Block discriminateBy="youtubeVideo" label="YouTube video">
			<YoutubeVideoField field="youtubeVideo" />
		</Block>
	</>
)

export interface ContentFieldProps {
	field: string
	label?: FormGroupProps['label']
}

export const ContentField = Component<ContentFieldProps>(props => (
	<HasOne field={props.field}>
		<BlockRepeater
			sortableBy="order"
			field="blocks"
			removalType="delete"
			discriminationField="type"
			label={props.label}
		>
			{contentBlocks}
		</BlockRepeater>
	</HasOne>
))
