import {
	AltPersistButton,
	Box,
	Field,
	HasOne,
	Literal,
	MultiEditPage,
	Repeater,
	SelectField,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { LinkField } from '../../components'

export const footerPageName = 'footer'

export const footerRoutes = {
	[footerPageName]: { path: '/settings/footer' },
}

export const FooterPage = (
	<MultiEditPage
		entities="Locale"
		pageName={footerPageName}
		rendererProps={{
			title: 'Patička',
			persistButtonComponent: AltPersistButton,
		}}
	>
		<Box title={undefined}>
			<h2>
				<Field field="caption" />
			</h2>

			<HasOne field="layout">
				<TextField field="footerCopyright" label="Copyright" />
				<TextField field="footerAuthor" label="Autor" />
				<Repeater field="footerMenuColumns" sortableBy="order" label="Sloupce">
					<TextField field="title" label="Nadpis sloupce" />
					<HasOne field="navigation">
						<Repeater field="items" sortableBy="order" label="Položky ve sloupci">
							<TextField field="caption" placeholder="Popisek" label={undefined} />
							<LinkField field="link" />
						</Repeater>
					</HasOne>
				</Repeater>
				<Repeater field="socialLinks" sortableBy="order" label="Odkazy na sociální sítě">
					<SelectField
						label="Síť"
						field="type"
						options={[
							{ value: new Literal('instagram'), label: 'Instagram' },
							{ value: new Literal('facebook'), label: 'Facebook' },
							{ value: new Literal('linkedin'), label: 'LinkedIn' },
							{ value: new Literal('youtube'), label: 'YouTube' },
							{ value: new Literal('twitter'), label: 'Twitter' },
							{ value: new Literal('pinterest'), label: 'Pinterest' },
						]}
					/>
					<TextField field="url" label="URL" />
				</Repeater>
				<HasOne field="footerBottomMenu">
					<Repeater field="items" sortableBy="order" label="Položky v dolním menu">
						<TextField field="caption" placeholder="Popisek" label={undefined} />
						<LinkField field="link" />
					</Repeater>
				</HasOne>
			</HasOne>
		</Box>
	</MultiEditPage>
)
