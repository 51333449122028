import { Component, FormGroupProps, HasOne, Repeater, SelectField } from '@contember/admin'
import * as React from 'react'

export interface ArticleListFieldProps {
	field: string
	label?: FormGroupProps['label']
}

export const ArticleListField = Component<ArticleListFieldProps>(props => (
	<HasOne field={props.field}>
		<Repeater field="items" sortableBy="order" label={props.label}>
			<SelectField field={'article'} label={'Článek'} options={'BlogArticle.title'} />
		</Repeater>
	</HasOne>
))
