import { productColorRoutes } from './ProductColorPages'
import { productSurfaceRoutes } from './ProductSurfacePages'
import { productCategoryRoutes } from './ProductCategoryPages'
import { productItselfRoutes } from './ProductPages'
import { productCompositionRoutes } from './ProductCompositionPages'
import { productSwatchRoutes } from './ProductSwatchPages'
import { productUsageRoutes } from './ProductUsagePages'

export * from './ProductColorPages'
export * from './ProductSurfacePages'
export * from './ProductCategoryPages'
export * from './ProductCompositionPages'
export * from './ProductSwatchPages'
export * from './ProductPages'
export * from './ProductPageNames'
export * from './ProductUsagePages'

export const productRoutes = {
	...productColorRoutes,
	...productSurfaceRoutes,
	...productCategoryRoutes,
	...productItselfRoutes,
	...productCompositionRoutes,
	...productSwatchRoutes,
	...productUsageRoutes,
}
