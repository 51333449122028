import {
	AltPersistButton,
	Component,
	CreatePage,
	DeleteEntityButton,
	EditPage,
	Field,
	ListPage,
	MultiEditPage,
	MultiSelectField,
	NavigateBackButton,
	PageLinkButton,
	Repeater,
	SelectField,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { EditButton, ImageField, LocaleSideDimension } from '../../components'
import { CategoryTreeContainer } from '../../components/CategoryTree'
import {
	documentCategoryCreatePageName,
	documentCategoryEditPageName,
	documentCategoryListPageName,
	documentCategoryOrderPageName,
} from './DocumentPageNames'

export const documentCategoryRoutes = {
	[documentCategoryListPageName]: { path: '/document/categories' },
	[documentCategoryCreatePageName]: { path: '/document/categories/new' },
	[documentCategoryOrderPageName]: { path: '/document/categories/order' },
	[documentCategoryEditPageName]: { path: '/document/categories/:id' },
}

const listPageName = documentCategoryListPageName
const createPageName = documentCategoryCreatePageName
const editPageName = documentCategoryEditPageName
const entityName = 'DocumentCategory'
const addNewTitle = 'Přidat novou kategorii dokumentů'
const listingTitle = 'Kategorie dokumentů'
const editTitle = 'Upravit kategorii dokumentů'

export const DocumentCategoryForm = Component(
	() => (
		<>
			<TextField field="internalName" label="Interní název" />
			<SelectField field="parent" label="Rodič" options="DocumentCategory.internalName" allowNull={true} />
			<LocaleSideDimension>
				<TextField field={'name'} label={'Název'} />
			</LocaleSideDimension>
			<ImageField field="icon" label="Ikonka" allowImageDisconnect />
			<MultiSelectField
				field="documents"
				label="Dokumenty"
				options={{
					fields: 'Document.internalName',
					orderBy: 'internalName asc',
				}}
			/>
			<Repeater
				field={'children'}
				label={'Řazení podkategorií'}
				sortableBy={'order'}
				enableRemoving={false}
				enableAddingNew={false}
			>
				<Field field={'internalName'} />
			</Repeater>
		</>
	),
	'DocumentCategoryForm',
)

export const DocumentCategoryCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
	>
		<DocumentCategoryForm />
	</CreatePage>
)
export const DocumentCategoryEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<DocumentCategoryForm />
	</EditPage>
)

const categoryBody = (
	<>
		<Field field="internalName" />
		<Field field="parent.internalName" format={value => null} />
		<span className="presbeton-categoryEditButton">
			<EditButton pageName={editPageName} />
			<DeleteEntityButton immediatePersist={true} />
		</span>
	</>
)

export const DocumentCategoryListPage = (
	<ListPage
		pageName={listPageName}
		entities={entityName}
		orderBy="internalName"
		rendererProps={{
			title: listingTitle,
			actions: (
				<>
					<PageLinkButton to={createPageName}>{addNewTitle}</PageLinkButton>
					<PageLinkButton to={documentCategoryOrderPageName}>Seřadit hlavní kategorie</PageLinkButton>
				</>
			),
			containerComponent: CategoryTreeContainer,
			containerComponentExtraProps: {
				categoryBody: categoryBody,
			},
		}}
	>
		{categoryBody}
	</ListPage>
)

export const DocumentCategoryOrderPage = (
	<MultiEditPage
		entities={{ entityName, filter: { parent: { id: { isNull: true } } } }}
		pageName={documentCategoryOrderPageName}
		rendererProps={{
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			title: 'Řazení hlavních kategorií dokumentů',
			sortableBy: 'order',
			persistButtonComponent: AltPersistButton,
			enableRemoving: false,
			enableAddingNew: false,
		}}
	>
		<Field field="internalName" />
	</MultiEditPage>
)
