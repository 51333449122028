import { ProjectConfig } from '@contember/admin'

const routes = {
	dashboard: { path: '/' },

	pageList: { path: '/pages' },
	pageCreate: { path: '/pages/new' },
	pageEdit: { path: '/pages/:id' },
	projectList: { path: '/projects' },
	projectCreate: { path: '/projects/new' },
	projectEdit: { path: '/projects/:id' },
	roomList: { path: '/rooms' },
	roomCreate: { path: '/rooms/new' },
	roomEdit: { path: '/rooms/:id' },
	newsletterGroupList: { path: '/newsletterGroups' },
	newsletterGroupCreate: { path: '/newsletterGroups/new' },
	newsletterGroupEdit: { path: '/newsletterGroups/:id' },
	personList: { path: '/people' },
	personCreate: { path: '/people/new' },
	personEdit: { path: '/people/:id' },
	bookingList: { path: '/bookings' },
	bookingCreate: { path: '/bookings/new' },
	bookingEdit: { path: '/bookings/:id' },
	buildingList: { path: '/buildings' },
	buildingCreate: { path: '/buildings/new' },
	buildingEdit: { path: '/buildings/:id' },
	footerPage: { path: '/footer/' },
	headerMenu: { path: '/menu/' },
	subscriberList: { path: '/subscribers' },
	subscriberCreate: { path: '/subscribers/new' },
	subscriberEdit: { path: '/subscribers/:id' },
	redirects: { path: '/redirects/' },
	equipmentList: { path: '/equipments' },
	equipmentCreate: { path: '/equipments/new' },
	equipmentEdit: { path: '/equipments/:id' },

	// tenant
	tenantChangePassword: { path: '/change-password' },
	tenantUsers: { path: '/users' },
	tenantInviteUser: { path: '/invite-user' },
	tenantEditUser: { path: '/edit-user/:id' },
}
const config: ProjectConfig[] = [
	{
		project: 'skautskyinstitut',
		stage: 'live',
		component: () => import('./live'),
		routes: routes,
	},
]

export default config
