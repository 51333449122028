import { contactCompanyRoutes } from './ContactCompanyPages'
import { contactCountryRoutes } from './ContactCountryPages'
import { contactDepartmentRoutes } from './ContactDepartmentPages'
import { contactFormRoutes } from './ContactFormPages'
import { contactOfficeRoutes } from './ContactOfficePages'
import { contactPersonRoutes } from './ContactPersonPages'
import { contactPlaceRoutes } from './ContactPlacePages'
import { contactRegionRoutes } from './ContactRegionPages'

export * from './ContactCompanyPages'
export * from './ContactCountryPages'
export * from './ContactDepartmentPages'
export * from './ContactFormPages'
export * from './ContactOfficePages'
export * from './ContactPageNames'
export * from './ContactPersonPages'
export * from './ContactPlacePages'
export * from './ContactRegionPages'

export const contactRoutes = {
	...contactCompanyRoutes,
	...contactCountryRoutes,
	...contactDepartmentRoutes,
	...contactOfficeRoutes,
	...contactFormRoutes,
	...contactRegionRoutes,
	...contactPersonRoutes,
	...contactPlaceRoutes,
}
