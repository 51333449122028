import { ProjectConfig } from '@contember/admin'

const routes = {
	dashboard: { path: '/' },

	productList: { path: '/products' },
	productCreate: { path: '/product/new' },
	productEdit: { path: '/product/:id' },
	variantList: { path: '/variants' },
	variantCreate: { path: '/variants/new' },
	variantEdit: { path: '/variants/:id' },
}
const config: ProjectConfig[] = [
	{
		project: 'coquette',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
