import {
	AltPersistButton,
	Component,
	CreatePage,
	DateFieldView,
	EditPage,
	Field,
	MultiSelectField,
	NavigateBackButton,
	NumberField,
	PageLinkButton,
	SelectField,
	TableCell,
	TablePage,
	TextAreaField,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { ContentField, EditButton, ImageField, IsPublishedField, LinkUrlField, SeoForm } from '../../components'
import { createReindexCallback } from '../../utils/createReindexCallback'
import { blogArticleCreatePageName, blogArticleEditPageName, blogArticleListPageName } from './BlogPageNames'

export const blogArticleRoutes = {
	[blogArticleListPageName]: { path: '/blog/articles' },
	[blogArticleCreatePageName]: { path: '/blog/articles/new' },
	[blogArticleEditPageName]: { path: '/blog/articles/:id' },
}

const listPageName = blogArticleListPageName
const createPageName = blogArticleCreatePageName
const editPageName = blogArticleEditPageName
const entityName = 'BlogArticle'
const addNewTitle = 'Přidat článek'
const listingTitle = 'Články magazínu'
const editTitle = 'Upravit článek'

export const BlogArticleForm = Component(
	() => (
		<>
			<TextField field="title" size="large" label="Titulek" />
			<LinkUrlField derivedFrom="title" label="URL" />
			<SelectField field="locale" options="Locale.caption" label="Jazyk" />
			<NumberField field="readingTime" label="Čas čtení" defaultValue={1} />
			<TextAreaField field="lead" label="Perex" />
			<ImageField field="tileImage" label="Obrázek pro dlaždici" allowImageDisconnect />
			<ContentField field="content" />
			<MultiSelectField
				field="topics"
				label="Kategorie a téma"
				options="BlogCategoryTopic"
				renderOption={accessor => {
					const name = accessor.getField<string>('name')
					const categoryName = accessor.getField<string>('category.name')
					return (
						<>
							{categoryName.currentValue} - {name.currentValue}
						</>
					)
				}}
				optionsStaticRender={
					<>
						<Field field="name" />
						<Field field="category.name" />
					</>
				}
			/>
			<SeoForm titleDerivedFrom={'title'} />
		</>
	),
	'BlogArticleForm',
)

export const BlogArticleFormSide = IsPublishedField

const DraftLabel = Component(() => <span>draft</span>, 'DraftLabel')
const grid = (
	<>
		<TableCell>
			<Field field={'title'} />
		</TableCell>
		<TableCell>
			<DateFieldView
				field="publishedAt"
				fallback={<DraftLabel />}
				format={date => (date.getTime() > new Date().getTime() ? <DraftLabel /> : null)}
			/>
		</TableCell>
	</>
)

export const BlogArticleCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		// onBeforePersist={createReindexCallback('reindexBlog')}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			side: <BlogArticleFormSide />,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
	>
		<BlogArticleForm />
	</CreatePage>
)
export const BlogArticleEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		onBeforePersist={createReindexCallback('reindexBlog')}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			side: <BlogArticleFormSide />,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<BlogArticleForm />
	</EditPage>
)

export const BlogArticleListPage = (
	<TablePage
		pageName={listPageName}
		entities={entityName}
		rendererProps={{
			title: listingTitle,
			actions: <PageLinkButton to={createPageName}>{addNewTitle}</PageLinkButton>,
		}}
	>
		{grid}
		<TableCell shrunk>
			<EditButton pageName={editPageName} />
		</TableCell>
	</TablePage>
)
