import { Box, EntityAccessor, EntityListAccessor, Menu, RepeaterContainerProps, SingleEntity } from '@contember/admin'
import * as React from 'react'

const getParentId = (entity: EntityAccessor): string | undefined => entity.getEntity('parent').primaryKey

const childAccessorFactory = (accessor: EntityListAccessor) => (parent: string | undefined) =>
	Array.from(accessor).filter(
		(it): it is EntityAccessor => it instanceof EntityAccessor && getParentId(it) === parent,
	) as EntityAccessor[]

type ChildAccessor = (parent: string | undefined) => EntityAccessor[]

const renderTree = (childAccessor: ChildAccessor, parent: string | undefined, children: React.ReactNode) => {
	const entities = childAccessor(parent)
	if (entities.length === 0) {
		return null
	}
	return entities.map(it => (
		<SingleEntity accessor={it} key={it.key}>
			<Menu.Item key={it.key} title={children} expandedByDefault={false}>
				{renderTree(childAccessor, it.primaryKey || undefined, children)}
			</Menu.Item>
		</SingleEntity>
	))
}

//export const CategoryItem

export const CategoryTreeContainer = React.memo((props: RepeaterContainerProps & { categoryBody: React.ReactNode }) => (
	<Box>
		<div className="presbeton-categoryTree">
			<Menu>
				<Menu.Item expandedByDefault>
					{renderTree(childAccessorFactory(props.accessor), undefined, props.categoryBody)}
				</Menu.Item>
			</Menu>
		</div>
	</Box>
))
CategoryTreeContainer.displayName = 'CategoryTreeContainer'
