import { ProjectConfig } from '@contember/admin'
import { translationRoutes } from '../../../plugins/translations/admin/routes'
import {
	blogRoutes,
	careerRoutes,
	contactRoutes,
	documentRoutes,
	helpRoutes,
	pageRoutes,
	productRoutes,
	redirectsRoutes,
	referenceRoutes,
	representativeRoutes,
	settingsRoutes,
	tenantRoutes,
} from './src/pages'

const routes = {
	dashboard: { path: '/' },
	...blogRoutes,
	...careerRoutes,
	...contactRoutes,
	...documentRoutes,
	...helpRoutes,
	...pageRoutes,
	...productRoutes,
	...referenceRoutes,
	...representativeRoutes,
	...settingsRoutes,
	...redirectsRoutes,
	...tenantRoutes,
	...translationRoutes(),
}
const config: ProjectConfig[] = [
	{
		project: 'presbeton',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
