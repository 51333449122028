import { footerRoutes } from './FooterPage'
import { localeRoutes } from './LocalePage'
import { headerRoutes } from './HeaderPage'

export * from './FooterPage'
export * from './HeaderPage'
export * from './LocalePage'

export const settingsRoutes = {
	...localeRoutes,
	...footerRoutes,
	...headerRoutes,
}
