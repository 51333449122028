export const translationRoutes = (prefix = 'translation') => ({
	translationDomainList: { path: `/${prefix}/domain` },
	translationDomainCreate: { path: `/${prefix}/domain/new` },
	translationDomainEdit: { path: `/${prefix}/domain/:id` },

	translationCatalogueList: { path: `/${prefix}/catalogue` },
	translationCatalogueCreate: { path: `/${prefix}/catalogue/new` },
	translationCatalogueEdit: { path: `/${prefix}/catalogue/:id` },

	translationValue: { path: `/${prefix}/value` },
})
