import { representativeCategoryRoutes } from './RepresentativeCategoryPages'
import { representativeItselfRoutes } from './RepresentativePages'

export * from './RepresentativeCategoryPages'
export * from './RepresentativePageNames'
export * from './RepresentativePages'

export const representativeRoutes = {
	...representativeCategoryRoutes,
	...representativeItselfRoutes,
}
