import { Component, Field, HasMany, useRelativeEntityList } from '@contember/admin'
import { useMemo } from 'react'
import * as React from 'react'

export const VariantRelationInfoField = Component(
	() => {
		const entities = useRelativeEntityList('variants')
		const names = useMemo((): string[] => {
			return Array.from(entities, accessor => accessor.getField<string>('internalName').currentValue!)
		}, [entities])
		if (names.length === 0) {
			return <>Žádný produkt</>
		}
		return (
			<span title={names.join('\n')} className={'variant-list'}>
				{names.length} produktů ({names[0].substring(0, 15).replace(/_/g, ' ').trim()}…)
			</span>
		)
	},
	() => (
		<HasMany field={'variants'}>
			<Field field={'internalName'} />
		</HasMany>
	),
)
