import {
	AltPersistButton,
	Component,
	CreatePage,
	EditPage,
	Field,
	NavigateBackButton,
	PageLinkButton,
	Repeater,
	SelectField,
	TableCell,
	TablePage,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { EditButton, LinkUrlField, LocaleSideDimension, ReferenceListField, SeoForm } from '../../components'
import {
	referenceCategoryCreatePageName,
	referenceCategoryEditPageName,
	referenceCategoryListPageName,
} from './ReferencePageNames'

export const referenceCategoryRoutes = {
	[referenceCategoryListPageName]: { path: '/references/categories' },
	[referenceCategoryCreatePageName]: { path: '/references/categories/new' },
	[referenceCategoryEditPageName]: { path: '/references/categories/:id' },
}

const listPageName = referenceCategoryListPageName
const createPageName = referenceCategoryCreatePageName
const editPageName = referenceCategoryEditPageName
const entityName = 'ReferenceCategory'
const addNewTitle = 'Přidat kategorii realizací'
const listingTitle = 'Kategorie realizací'
const editTitle = 'Upravit kategorii realizací'

const grid = (
	<TableCell>
		<Field field="internalName" />
	</TableCell>
)
export const ReferenceCategoryForm = Component(
	() => (
		<>
			<TextField field="internalName" label="Interní název" />
			<LinkUrlField derivedFrom="internalName" label="URL" persistedSoftPrefix={'realizace/'} />
			<Repeater field="items" sortableBy="order" label={undefined}>
				<SelectField field={'reference'} label={'Realizace'} options={'Reference.internalName'} />
			</Repeater>
			<LocaleSideDimension>
				<TextField field="name" label="Název" />
				<SeoForm titleDerivedFrom={'name'} />
			</LocaleSideDimension>
		</>
	),
	'ReferenceCategoryForm',
)

export const ReferenceCategoryCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
	>
		<ReferenceCategoryForm />
	</CreatePage>
)
export const ReferenceCategoryEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<ReferenceCategoryForm />
	</EditPage>
)

export const ReferenceCategoryListPage = (
	<TablePage
		pageName={listPageName}
		entities={entityName}
		rendererProps={{
			title: listingTitle,
			actions: <PageLinkButton to={createPageName}>{addNewTitle}</PageLinkButton>,
		}}
	>
		{grid}
		<TableCell shrunk>
			<EditButton pageName={editPageName} />
		</TableCell>
	</TablePage>
)
