import { Component, Field, FormGroupProps, HasOne, Repeater, SelectField } from '@contember/admin'
import * as React from 'react'
import { ImagePreview } from './ImagePreviewField'

export interface ReferenceListFieldProps {
	field: string
	label?: FormGroupProps['label']
}

export const ReferenceListField = Component<ReferenceListFieldProps>(props => (
	<HasOne field={props.field}>
		<Repeater field="items" sortableBy="order" label={props.label}>
			<SelectField
				field="reference"
				label="Realizace"
				options="Reference"
				renderOption={accessor => {
					const internalName = accessor.getField<string>('internalName')
					const image = accessor.getField<string>('image.url')
					return (
						<span style={{ display: 'flex', alignItems: 'center' }}>
							<ImagePreview src={image.currentValue} />
							{internalName.currentValue}
						</span>
					)
				}}
				optionsStaticRender={
					<>
						<Field field="internalName" />
						<Field field="image.url" />
					</>
				}
				searchByFields={['internalName']}
			/>
		</Repeater>
	</HasOne>
))
