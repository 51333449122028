import {
	AltPersistButton,
	Component,
	CreatePage,
	EditPage,
	Field,
	NavigateBackButton,
	PageLinkButton,
	TableCell,
	TablePage,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { EditButton, ImageField, ImagePreviewField, LocaleSideDimension } from '../../components'
import { productColorCreatePageName, productColorEditPageName, productColorListPageName } from './ProductPageNames'

export const productColorRoutes = {
	[productColorListPageName]: { path: '/product/colors' },
	[productColorCreatePageName]: { path: '/product/colors/new' },
	[productColorEditPageName]: { path: '/product/colors/:id' },
}

const listPageName = productColorListPageName
const createPageName = productColorCreatePageName
const editPageName = productColorEditPageName
const entityName = 'ProductColor'
const addNewTitle = 'Přidat barvu produktu'
const listingTitle = 'Barvy produktů'
const editTitle = 'Upravit barvu produktu'

const grid = (
	<>
		<TableCell shrunk>
			<ImagePreviewField srcField="image.url" circle standalone />
		</TableCell>
		<TableCell>
			<Field field={'internalName'} />
		</TableCell>
	</>
)
export const ProductColorForm = Component(
	() => (
		<>
			<TextField field="internalName" label="Interní název" />
			<ImageField field={'image'} label={'Obrázek'} allowImageDisconnect />
			<LocaleSideDimension>
				<TextField field="name" label="Barva" />
			</LocaleSideDimension>
		</>
	),
	'ProductColorForm',
)

export const ProductColorCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
	>
		<ProductColorForm />
	</CreatePage>
)
export const ProductColorEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<ProductColorForm />
	</EditPage>
)

export const ProductColorListPage = (
	<TablePage
		pageName={listPageName}
		entities={entityName}
		orderBy={'internalName'}
		rendererProps={{
			title: listingTitle,
			actions: <PageLinkButton to={createPageName}>{addNewTitle}</PageLinkButton>,
		}}
	>
		{grid}
		<TableCell shrunk>
			<EditButton pageName={editPageName} />
		</TableCell>
	</TablePage>
)
