import {
	ActionableBox,
	Component,
	FormGroupProps,
	HasOne,
	ImageUploadField,
	useParentEntityAccessor,
	useRelativeSingleEntity,
} from '@contember/admin'
import * as React from 'react'

export interface ImageFieldProps {
	field: string
	label?: FormGroupProps['label']
	description?: FormGroupProps['description']
	labelDescription?: FormGroupProps['labelDescription']
	allowImageDisconnect?: boolean
}

export const ImageField = Component<ImageFieldProps>((props: ImageFieldProps) => {
	const urlField = 'url'
	const imageBase = (
		<HasOne field={props.field}>
			<ImageUploadField
				label={props.label}
				field={urlField}
				description={props.description}
				labelDescription={props.labelDescription}
				imageWidthField="width"
				imageHeightField="height"
				fileSizeField="size"
				fileTypeField="type"
			/>
		</HasOne>
	)

	if (!props.allowImageDisconnect) {
		return imageBase
	}

	return (
		<ImageFieldRemover field={props.field} urlField={urlField}>
			{imageBase}
		</ImageFieldRemover>
	)
})

const ImageFieldRemover = Component<{ field: string; urlField: string; children: React.ReactNode }>(
	props => {
		const imageField = props.field
		const parentEntity = useParentEntityAccessor()

		// It's important to use the hook and not just parentEntity.getEntity(imageField) so that we get notified about
		// connection updates.
		const childEntity = useRelativeSingleEntity(props)

		// We don't want parentEntity as in the dependency array and disconnectEntityAtField is guaranteed to be stable.
		const disconnectEntityAtField = parentEntity.disconnectEntityAtField
		const remove = React.useCallback(() => {
			disconnectEntityAtField(imageField)
		}, [disconnectEntityAtField, imageField])

		const childHasImage = childEntity.getField(props.urlField).currentValue !== null
		// When there's no callback supplied, the button gets hidden.
		const onRemove = childHasImage ? remove : undefined

		return (
			<div style={{ marginTop: 'var(--cui-formGroup-separation)' }}>
				<ActionableBox onRemove={onRemove} key={parentEntity.getEntity(imageField).key}>
					{props.children}
				</ActionableBox>
			</div>
		)
	},
	props => <>{props.children}</>,
	'ImageFieldRemover',
)
