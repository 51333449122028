import {
	AltPersistButton,
	Box,
	CheckboxField,
	Component,
	CreatePage,
	DeleteEntityButton,
	EditPage,
	Field,
	HasOne,
	ListPage,
	Literal,
	MultiSelectField,
	NavigateBackButton,
	PageLinkButton,
	RichTextField,
	SelectField,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import {
	ContentField,
	DocumentListField,
	EditButton,
	ImageField,
	LinkUrlField,
	LocaleSideDimension,
	ReferenceListField,
	SeoForm,
} from '../../components'
import { CategoryTreeContainer } from '../../components/CategoryTree'
import { YoutubeVideoListField } from '../../components/YoutubeVideoListField'
import {
	productCategoryCreatePageName,
	productCategoryEditPageName,
	productCategoryListPageName,
} from './ProductPageNames'

export const productCategoryRoutes = {
	[productCategoryListPageName]: { path: '/product/categories' },
	[productCategoryCreatePageName]: { path: '/product/categories/new' },
	[productCategoryEditPageName]: { path: '/product/categories/:id' },
}

const listPageName = productCategoryListPageName
const createPageName = productCategoryCreatePageName
const editPageName = productCategoryEditPageName
const entityName = 'ProductCategory'
const addNewTitle = 'Přidat produktovou kategorii'
const listingTitle = 'Produktové kategorie'
const editTitle = 'Upravit produktovou kategorii'

export const ProductCategoryForm = Component(
	() => (
		<>
			<TextField field="internalName" label="Interní název" />
			<LinkUrlField derivedFrom="internalName" label="URL" persistedSoftPrefix="produkty-realizace/" />
			<SelectField
				label="Druh kategorie"
				options={[
					{ value: new Literal('generic'), label: 'Obecná kategorie' },
					{ value: new Literal('productLine'), label: 'Produktová řada' },
					{ value: new Literal('system'), label: 'Systém' },
				]}
				field="kind"
			/>
			<HasOne field="filters">
				<Box heading="Aktivní filtry">
					<CheckboxField field="price" label="Cena" isNonbearing={false} defaultValue={false} />
					<CheckboxField field="length" label="Délka" isNonbearing={false} defaultValue={false} />
					<CheckboxField field="height" label="Výška" isNonbearing={false} defaultValue={false} />
					<CheckboxField field="width" label="Šířka" isNonbearing={false} defaultValue={false} />
					<CheckboxField field="usage" label="Použití" isNonbearing={false} defaultValue={false} />
					<CheckboxField field="color" label="Barva" isNonbearing={false} defaultValue={false} />
					<CheckboxField field="surface" label="Povrch" isNonbearing={false} defaultValue={false} />
					<CheckboxField field="attributes" label="Vlastnosti" isNonbearing={false} defaultValue={false} />
				</Box>
			</HasOne>
			<ImageField field="tileImage" label="Obrázek dlaždice" allowImageDisconnect />
			<ImageField field="introImage" label="Úvodní obrázek" allowImageDisconnect />
			<SelectField field="parent" label="Rodič" options="ProductCategory.internalName" allowNull={true} />
			<ReferenceListField field="references" />
			<DocumentListField field="documents" label="Dokumenty" />
			<YoutubeVideoListField field="youtubeVideos" label="YouTube" />
			<MultiSelectField field={'products'} label={'Produkty'} options={'Product.internalName'} />
			<MultiSelectField field="compositions" label="Skladebnosti" options="ProductComposition.internalName" />
			<LocaleSideDimension>
				<Box>
					<TextField field="name" label="Název" size="large" />
					<RichTextField field="lead" label="Perex" />
				</Box>
				<ContentField field="content" />
				<SeoForm titleDerivedFrom="name" />
			</LocaleSideDimension>
		</>
	),
	'ProductCategoryForm',
)

export const ProductCategoryCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
	>
		<ProductCategoryForm />
	</CreatePage>
)
export const ProductCategoryEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<ProductCategoryForm />
	</EditPage>
)

const categoryBody = (
	<>
		<Field field="internalName" />
		<Field field="parent.internalName" format={value => null} />
		<Field field="externalIdentifier" format={value => (!value ? ' (marketingová kategorie)' : null)} />
		<span className="presbeton-categoryDeleteButton">
			<DeleteEntityButton Component="a" immediatePersist={true} size="default" />
		</span>
		<span className="presbeton-categoryEditButton">
			<EditButton pageName={editPageName} />
		</span>
	</>
)

export const ProductCategoryListPage = (
	<ListPage
		pageName={listPageName}
		entities={entityName}
		orderBy="internalName"
		rendererProps={{
			title: listingTitle,
			actions: <PageLinkButton to={createPageName}>{addNewTitle}</PageLinkButton>,
			containerComponent: CategoryTreeContainer,
			containerComponentExtraProps: {
				categoryBody: categoryBody,
			},
		}}
	>
		{categoryBody}
	</ListPage>
)
