export const productCategoryListPageName = 'productCategoryList'
export const productCategoryCreatePageName = 'productCategoryCreate'
export const productCategoryEditPageName = 'productCategoryEdit'

export const productColorListPageName = 'productColorList'
export const productColorCreatePageName = 'productColorCreate'
export const productColorEditPageName = 'productColorEdit'

export const productListPageName = 'productList'
export const productEditPageName = 'productEdit'

export const productSurfaceListPageName = 'productSurfaceList'
export const productSurfaceCreatePageName = 'productSurfaceCreate'
export const productSurfaceEditPageName = 'productSurfaceEdit'

export const productCompositionListPageName = 'productCompositionList'
export const productCompositionCreatePageName = 'productCompositionCreate'
export const productCompositionEditPageName = 'productCompositionEdit'

export const productSwatchListPageName = 'productSwatchList'
export const productSwatchCreatePageName = 'productSwatchCreate'
export const productSwatchEditPageName = 'productSwatchEdit'

export const productUsageListPageName = 'productUsageList'
export const productUsageCreatePageName = 'productUsageCreate'
export const productUsageEditPageName = 'productUsageEdit'
