import { ProjectConfig } from '@contember/admin'
import { tenantRoutes } from '../../../plugins/tenant/admin/routes'

const routes = {
	dashboard: { path: '/' },

	homepage: { path: '/homepage' },
	aboutpage: { path: '/aboutpage' },
	footermenu: { path: '/footermenu' },
	eventcreate: { path: '/eventcreate' },
	eventedit: { path: '/eventedit/:id' },
	eventlist: { path: '/eventlist' },
	headermenu: { path: '/headermenu' },
	eventspage: { path: '/eventspage' },

	...tenantRoutes(),
}
const config: ProjectConfig[] = [
	{
		project: 'uolklub',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
