import { ProjectConfig } from '@contember/admin'

const routes = {
	dashboard: { path: '/' },
	homePage: { path: '/homepage' },
	aboutPage: { path: '/aboutpage' },
	blogPosts: { path: '/blogposts' },
	blogPostCreate: { path: '/blogposts/new' },
	blogPostEdit: { path: '/blogposts/:id' },
	footer: { path: '/footer' },

	// tenant
	tenantChangePassword: { path: '/change-password' },
	tenantUsers: { path: '/users' },
	tenantInviteUser: { path: '/invite-user' },
	tenantEditUser: { path: '/edit-user/:id' },
}
const config: ProjectConfig[] = [
	{
		project: 'foodiversity',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
