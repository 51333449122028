import { Component, FormGroupProps, HasOne, Repeater, SelectField } from '@contember/admin'
import * as React from 'react'

export interface ProductCategoryListFieldProps {
	field: string
	label?: FormGroupProps['label']
}

export const ProductCategoryListField = Component<ProductCategoryListFieldProps>(props => (
	<HasOne field={props.field}>
		<Repeater field="items" sortableBy="order" label={props.label}>
			<SelectField field={'category'} label={'Kategorie'} options={'ProductCategory.internalName'} />
		</Repeater>
	</HasOne>
))
