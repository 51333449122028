import { ProjectConfig } from '@contember/admin'
import { translationRoutes } from '../../../plugins/translations/admin/routes'

const routes = {
	dashboard: { path: '/' },

	frontPage: { path: '/fronticonSet' },

	// tenant
	tenantChangePassword: { path: '/change-password' },
	tenantUsers: { path: '/users' },
	tenantInviteUser: { path: '/invite-user' },
	tenantEditUser: { path: '/edit-user/:id' },

	locales: { path: '/locales' },
	stores: { path: '/stores' },

	iconSetList: { path: '/iconsets' },
	iconSetCreate: { path: '/iconsets/new' },
	iconSetEdit: { path: '/iconsets/:id' },

	productList: { path: '/products' },
	productCreate: { path: '/products/new' },
	productEdit: { path: '/products/:id' },

	productVariantList: { path: '/variants' },
	productVariantCreate: { path: '/variants/new' },
	productVariantEdit: { path: '/variants/:id' },

	reviewList: { path: '/reviews' },
	reviewCreate: { path: '/reviews/new' },
	reviewEdit: { path: '/reviews/:id' },

	pageList: { path: '/pages' },
	pageCreate: { path: '/pages/new' },
	pageEdit: { path: '/pages/:id' },

	...translationRoutes(),
}
const config: ProjectConfig[] = [
	{
		project: 'clotino',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
