import { ProjectConfig } from '@contember/admin'

const routes = {
	dashboard: { path: '/' },

	pageList: { path: '/pages' },
	pageCreate: { path: '/page/new' },
	pageEdit: { path: '/page/:id' },
	postList: { path: '/posts' },
	postCreate: { path: '/post/new' },
	postEdit: { path: '/post/:id' },
	categoryList: { path: '/categories' },
	categoryCreate: { path: '/category/new' },
	categoryEdit: { path: '/category/:id' },
	trackList: { path: '/tracks' },
	trackListForApproval: { path: '/tracks/approval' },
	trackCreate: { path: '/tracks/new' },
	trackEdit: { path: '/tracks/:id' },
}
const config: ProjectConfig[] = [
	{
		project: 'jagersoutez',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
