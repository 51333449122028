import { ProjectConfig } from '@contember/admin'
import { translationRoutes } from '../../../plugins/translations/admin/routes'

const routes = {
	dashboard: { path: '/' },

	// pages
	homePage: { path: '/homepage' },
	buyPage: { path: '/buypage' },
	contactPage: { path: '/contactpage' },
	drinkPage: { path: '/drinkpage' },
	growPage: { path: '/growpage' },
	makePage: { path: '/makepage' },
	spreadPage: { path: '/spreadpage' },
	teamPage: { path: '/teampage' },
	blogPage: { path: '/blogPage' },
	thinkPage: { path: '/thinkpage' },
	errorPage: { path: '/errorpage' },

	blogPostList: { path: '/blogposts' },
	blogPostCreate: { path: '/blogposts/new' },
	blogPostEdit: { path: '/blogposts/:id' },

	blogPostCategory: { path: '/blogPostCategory' },

	genericPageList: { path: '/genericpages' },
	genericPageCreate: { path: '/genericpages/new' },
	genericPageEdit: { path: '/genericpages/:id' },

	// products
	productList: { path: '/products' },
	productCreate: { path: '/products/new' },
	productEdit: { path: '/products/:id' },

	categoryList: { path: '/categories' },

	// vineyards
	vineyardList: { path: '/vineyards' },
	vineyardCreate: { path: '/vineyards/new' },
	vineyardEdit: { path: '/vineyards/:id' },

	// distributors
	distributorList: { path: '/distributors' },
	distributorCreate: { path: '/distributors/new' },
	distributorEdit: { path: '/distributors/:id' },

	newsletterSubscriptionList: { path: '/newsletterSubscriptions' },

	// menus
	footerMenu: { path: '/footer-menu' },
	headerMenu: { path: '/header-menu' },
	ageVerification: { path: '/age-verification' },

	cookieBar: { path: '/cookieBar' },

	// tenant
	tenantChangePassword: { path: '/change-password' },
	tenantUsers: { path: '/users' },
	tenantInviteUser: { path: '/invite-user' },
	tenantEditUser: { path: '/edit-user/:id' },

	// redirects
	redirects: { path: '/redirects' },

	// locales
	locales: { path: '/locales' },

	...translationRoutes(),
}
const config: ProjectConfig[] = [
	{
		project: 'nestarec',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
