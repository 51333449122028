import {
	AltPersistButton,
	Box,
	CheckboxField,
	EditPage,
	Field,
	HasOne,
	MultiEditPage,
	NavigateBackButton,
	Repeater,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { CollapsibleBox, EditButton, ImageField, LinkField } from '../../components'

export const headerPageName = 'header'
export const headerProductNavigationPageName = 'headerProductNavigation'

export const headerRoutes = {
	[headerPageName]: { path: '/settings/header' },
	[headerProductNavigationPageName]: { path: '/settings/header/product-navigation/:id' },
}

export const HeaderPage = (
	<MultiEditPage
		entities="Locale"
		pageName={headerPageName}
		rendererProps={{
			title: 'Hlavička',
			persistButtonComponent: AltPersistButton,
		}}
	>
		<Box title={undefined}>
			<h2>
				<Field field="caption" />
			</h2>

			<HasOne field="layout">
				<HasOne field="headerRightMenu">
					<Repeater field="items" sortableBy="order" label="Položky v pravém menu">
						<TextField field="caption" placeholder="Popisek" label={undefined} />
						<LinkField field="link" />
					</Repeater>
				</HasOne>
				<HasOne field="headerMainMenu">
					<Repeater field="items" sortableBy="order" label="Položky v hlavním menu">
						<TextField field="caption" placeholder="Popisek" label={undefined} />
						<LinkField field="link" />
						<EditButton pageName={headerProductNavigationPageName}>Nastavit produktovou navigaci</EditButton>
					</Repeater>
				</HasOne>
			</HasOne>
		</Box>
	</MultiEditPage>
)

export const HeaderProductNavigationPage = (
	<EditPage
		entity={{ entityName: 'NavigationItem', where: '(id = $id)' }}
		pageName={headerProductNavigationPageName}
		rendererProps={{
			title: 'Produktová navigace',
			persistButtonComponent: AltPersistButton,
			navigation: <NavigateBackButton to={headerPageName}>Nastavení hlavičky</NavigateBackButton>,
		}}
	>
		<HasOne field="productNavigation">
			<Repeater field="items" sortableBy="order" label={undefined}>
				<TextField field="caption" placeholder="Popisek" label={undefined} />
				<CollapsibleBox heading="Odkaz a podpoložky">
					<LinkField field="link" />
					<Repeater field="primaryNavigation.items" sortableBy="order" label="Hlavní navigace">
						<TextField field="caption" placeholder="Popisek" label={undefined} />
						<CheckboxField field="highlighted" label="Zvýraznit" />
						<LinkField field="link" />
					</Repeater>
					<Repeater field="boxedNavigation.items" sortableBy="order" label="Druhá navigace">
						<TextField field="caption" placeholder="Popisek" label={undefined} />
						<LinkField field="link" />
					</Repeater>
					<Repeater field="imageNavigation.items" sortableBy="order" label="Obrázková navigace">
						<TextField field="caption" placeholder="Popisek" label={undefined} />
						<LinkField field="link" />
						<ImageField field="image" label="Obrázek" />
					</Repeater>
				</CollapsibleBox>
			</Repeater>
		</HasOne>
	</EditPage>
)
