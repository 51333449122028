import { Component, Field, SelectField, SelectFieldInnerPublicProps } from '@contember/admin'
import * as React from 'react'
import { ImagePreview } from './ImagePreviewField'

interface SelectProductColorFieldProps {
	field: string
	allowNull?: SelectFieldInnerPublicProps['allowNull']
}

export const SelectProductColorField = Component<SelectProductColorFieldProps>(({ field, allowNull }) => (
	<SelectField
		field={field}
		label="Barva"
		options="ProductColor"
		allowNull={allowNull}
		renderOption={accessor => {
			const internalName = accessor.getField<string>('internalName')
			const image = accessor.getField<string>('image.url')
			return (
				<span style={{ display: 'flex', alignItems: 'center' }}>
					<ImagePreview src={image.currentValue} circle />
					{internalName.currentValue}
				</span>
			)
		}}
		optionsStaticRender={
			<>
				<Field field="internalName" />
				<Field field="image.url" />
			</>
		}
		searchByFields={['internalName']}
	/>
))
