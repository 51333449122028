import {
	Block,
	Component,
	DiscriminatedBlocks,
	HasOne,
	SelectField,
	TextField,
	useDynamicSingleChoiceField,
	useRelativeSingleField,
} from '@contember/admin'
import * as React from 'react'

export interface LinkFieldProps {
	field: string
}

const internalLinkSelectOptions = {
	fields: 'Linkable.url',
	orderBy: 'url',
}
const LinkFieldInner = Component(props => (
	<DiscriminatedBlocks field="type" label="Typ odkazu">
		<Block discriminateBy="internal" label="Interní">
			<SelectField field="internalLink" label="URL" options={internalLinkSelectOptions} />
			<TextField field="internalLinkQuery" label="Query parametry" />
		</Block>
		<Block discriminateBy="external" label="Externí">
			<TextField field="externalLink" label="URL" />
		</Block>
	</DiscriminatedBlocks>
))

export const LinkField = Component<LinkFieldProps>(
	props => {
		const typeField = useRelativeSingleField<string>(`${props.field}.type`)
		const internalLinkFieldProps = React.useMemo(
			() => ({
				field: `${props.field}.internalLink`,
				options: internalLinkSelectOptions,
			}),
			[props.field],
		)
		const internalLink = useDynamicSingleChoiceField(internalLinkFieldProps)

		const externalLink = useRelativeSingleField<string>(`${props.field}.externalLink`)
		const renderer = React.useCallback(() => {
			if (typeField.currentValue === 'internal' && internalLink.currentValue > 0) {
				return (
					<div className={'cui-input presbeton-linkConcealable-placeholder'}>
						Interní odkaz {internalLink.data[internalLink.currentValue].label}
					</div>
				)
			} else if (typeField.currentValue === 'external') {
				return (
					<div className={'cui-input presbeton-linkConcealable-placeholder'}>
						Externí odkaz {externalLink.currentValue}
					</div>
				)
			}
			return <div className={'cui-input presbeton-linkConcealable-placeholder'}>Nastavit odkaz</div>
		}, [typeField.currentValue, internalLink.currentValue, internalLink.data, externalLink.currentValue])
		const [isEditing, setEditing] = React.useState(!typeField.currentValue)

		return (
			<div className={'presbeton-linkConcealable'}>
				<HasOne field={props.field}>
					{isEditing ? <LinkFieldInner /> : <div onClick={() => setEditing(true)}>{renderer()}</div>}
				</HasOne>
			</div>
		)
	},
	props => (
		<HasOne field={props.field}>
			<LinkFieldInner />
		</HasOne>
	),
)
