import { ProjectConfig } from '@contember/admin'

const routes = {
	dashboard: { path: '/' },

	pageList: { path: '/pages' },
	pageCreate: { path: '/pages/new' },
	pageEdit: { path: '/pages/:id' },
	locationList: { path: '/locations' },
	locationCreate: { path: '/locations/new' },
	locationEdit: { path: '/locations/:id' },
	locationCategoryList: { path: '/locationCategorys' },
	locationCategoryCreate: { path: '/locationCategorys/new' },
	locationCategoryEdit: { path: '/locationCategorys/:id' },
	eventCategoryList: { path: '/eventCategorys' },
	eventCategoryCreate: { path: '/eventCategorys/new' },
	eventCategoryEdit: { path: '/eventCategorys/:id' },
	eventList: { path: '/events' },
	eventCreate: { path: '/events/new' },
	eventEdit: { path: '/event/:id' },
	busList: { path: '/buses' },
	busCreate: { path: '/buses/new' },
	busEdit: { path: '/buses/:id' },
	tramList: { path: '/trams' },
	tramCreate: { path: '/trams/new' },
	tramEdit: { path: '/trams/:id' },
	stationList: { path: '/stations' },
	stationCreate: { path: '/stations/new' },
	stationEdit: { path: '/stations/:id' },
	postList: { path: '/posts' },
	postCreate: { path: '/posts/new' },
	postEdit: { path: '/posts/:id' },
	footerPage: { path: '/footer' },
	headerMenu: { path: '/menu' },
	kmlPage: { path: '/kml' },

	// tenant
	tenantChangePassword: { path: '/change-password' },
	tenantUsers: { path: '/users' },
	tenantInviteUser: { path: '/invite-user' },
	tenantEditUser: { path: '/edit-user/:id' },

	// locales
	locales: { path: '/locales' },
}
const config: ProjectConfig[] = [
	{
		project: 'prazskamuzejninoc',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
