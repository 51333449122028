import {
	Box,
	Component,
	DerivedFieldLink,
	DerivedFieldLinkProps,
	HasOne,
	TextAreaField,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { ImageField } from './index'

export interface SeoFormProps {
	titleDerivedFrom: DerivedFieldLinkProps['derivedField']
}

export const SeoForm = Component<SeoFormProps>(
	props => (
		<Box heading="Page SEO">
			{props.titleDerivedFrom && <DerivedFieldLink sourceField={props.titleDerivedFrom} derivedField="seo.title" />}
			<HasOne field="seo">
				<TextField field="title" label="Titulek stránky" />
				<TextAreaField field="description" label="Popis stránky" />
				<ImageField
					field="ogImage"
					label="OG obrázek"
					description="Doporučený poměr stran 19:10. např. 2400×1260 px."
					allowImageDisconnect
				/>
				<TextField field="ogTitle" label="OG nadpis" />
				<TextAreaField field="ogDescription" label="OG popis" />
			</HasOne>
		</Box>
	),
	'SeoForm',
)
