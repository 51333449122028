import {
	AltPersistButton,
	Component,
	CreatePage,
	EditPage,
	Field,
	MultiEditPage,
	NavigateBackButton,
	PageLinkButton,
	SelectField,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { ContentField, EditButton, IsPublishedField, LinkUrlField, SeoForm } from '../../components'
import { createReindexCallback } from '../../utils/createReindexCallback'
import { helpQuestionCreatePageName, helpQuestionEditPageName, helpQuestionListPageName } from './HelpPageNames'

export const helpQuestionRoutes = {
	[helpQuestionListPageName]: { path: '/help/questions' },
	[helpQuestionCreatePageName]: { path: '/help/questions/new' },
	[helpQuestionEditPageName]: { path: '/help/questions/:id' },
}

const listPageName = helpQuestionListPageName
const createPageName = helpQuestionCreatePageName
const editPageName = helpQuestionEditPageName
const entityName = 'HelpQuestion'
const addNewTitle = 'Přidat dotaz'
const listingTitle = 'Dotazy'
const editTitle = 'Upravit dotaz'

export const HelpQuestionForm = Component(
	() => (
		<>
			<TextField field="title" label="Dotaz" allowNewlines />
			<LinkUrlField derivedFrom="title" label="URL" persistedSoftPrefix="dotaz/" />
			<ContentField label="Odpověď" field="content" />
			<SelectField label="Téma" options="HelpTopic.name" field="topic" />
			<SeoForm titleDerivedFrom="title" />
		</>
	),
	'HelpQuestionForm',
)

export const HelpQuestionFormSide = IsPublishedField

export const HelpQuestionCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		// onBeforePersist={createReindexCallback('reindexHelp')}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			side: <HelpQuestionFormSide />,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
		orderField="order"
	>
		<HelpQuestionForm />
	</CreatePage>
)
export const HelpQuestionEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		onBeforePersist={createReindexCallback('reindexHelp')}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			side: <HelpQuestionFormSide />,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<HelpQuestionForm />
	</EditPage>
)

export const HelpQuestionListPage = (
	<MultiEditPage
		pageName={listPageName}
		entities={entityName}
		rendererProps={{
			title: listingTitle,
			sortableBy: 'order',
			enableAddingNew: false,
			persistButtonComponent: AltPersistButton,
			afterContent: (
				<PageLinkButton flow="block" to={createPageName}>
					{addNewTitle}
				</PageLinkButton>
			),
		}}
	>
		<div style={{ whiteSpace: 'normal' }}>
			<Field field="title" />
		</div>
		<EditButton pageName={editPageName} />
	</MultiEditPage>
)
