import {
	AltPersistButton,
	Component,
	CreatePage,
	EditPage,
	Field,
	MultiEditPage,
	NavigateBackButton,
	PageLinkButton,
	SelectField,
	TableCell,
	TablePage,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { ContentField, EditButton, IsPublishedField } from '../../components'
import {
	careerCategoryPageName,
	careerPositionCreatePageName,
	careerPositionEditPageName,
	careerPositionListPageName,
	careerPositionOrderPageName,
} from './CareerPageNames'

export const careerPositionRoutes = {
	[careerPositionListPageName]: { path: '/career/positions' },
	[careerPositionCreatePageName]: { path: '/career/positions/new' },
	[careerPositionOrderPageName]: { path: '/career/positions/order/:id' },
	[careerPositionEditPageName]: { path: '/career/positions/:id' },
}

const listPageName = careerPositionListPageName
const createPageName = careerPositionCreatePageName
const editPageName = careerPositionEditPageName
const entityName = 'CareerPosition'
const addNewTitle = 'Přidat pozici'
const listingTitle = 'Kariéra'
const editTitle = 'Upravit pozici'

export const CareerPositionForm = Component(
	() => (
		<>
			<TextField field="title" label="Nadpis" allowNewlines />
			<ContentField label="Obsah" field="content" />
			<SelectField label="Kategorie" options="CareerCategory.name" field="category" />
		</>
	),
	'CareerPositionForm',
)

export const CareerPositionFormSide = IsPublishedField

export const CareerPositionCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			side: <CareerPositionFormSide />,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
		orderField="order"
	>
		<CareerPositionForm />
	</CreatePage>
)
export const CareerPositionEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			side: <CareerPositionFormSide />,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<CareerPositionForm />
	</EditPage>
)

export const CareerPositionListPage = (
	<TablePage
		pageName={listPageName}
		entities={entityName}
		rendererProps={{
			title: listingTitle,
			actions: <PageLinkButton to={createPageName}>{addNewTitle}</PageLinkButton>,
		}}
	>
		<TableCell>
			<Field field="title" />
		</TableCell>
		<TableCell shrunk>
			<EditButton pageName={editPageName} />
		</TableCell>
	</TablePage>
)

export const CareerPositionOrderPage = (
	<MultiEditPage
		pageName={careerPositionOrderPageName}
		entities="CareerPosition[category.id = $id]"
		rendererProps={{
			title: 'Seřadit pozice',
			sortableBy: 'order',
			enableAddingNew: false,
			enableRemoving: false,
			navigation: <NavigateBackButton to={careerCategoryPageName}>Kategorie pozic</NavigateBackButton>,
		}}
	>
		<TextField field="title" label="Pozice" readOnly />
	</MultiEditPage>
)
