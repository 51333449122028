import { EntityAccessor } from '@contember/binding'

export const createReindexCallback = (endpoint: string) => {
	return (getAccessor: () => EntityAccessor) => {
		// fixme: workaround until workspace update
		const accessor = getAccessor()
		const id = accessor.primaryKey
		if (!id) {
			return
		}
		window.setTimeout(async () => {
			const baseUrl = accessor.environment.getValue('PRESBETON_WEB_URL')
			try {
				await fetch(`${baseUrl}/api/${endpoint}`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ id }),
				})
			} catch (e) {
				console.error(e)
			}
		}, 3000)
	}
}
