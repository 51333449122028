import { ProjectConfig } from '@contember/admin'
import { translationRoutes } from '../../../../plugins/translations/admin/routes'

const routes = {
	dashboard: { path: '/' },

	frontPage: { path: '/frontpage' },

	magazineArticleList: { path: '/magazineArticles' },
	wikiArticleList: { path: '/wikiArticles' },
	podcastArticleList: { path: '/podcastArticles' },
	tvArticleList: { path: '/tvArticles' },
	productNewsArticleList: { path: '/productNewsArticles' },
	articleCreate: { path: '/articles/new' },
	articleEdit: { path: '/articles/:id' },

	articlesPage: { path: '/articlespage' },

	cookieBar: { path: '/cookieBar' },

	issueOfTheWeekList: { path: '/issueOfTheWeek/list' },
	issueOfTheWeekCreate: { path: '/issueOfTheWeek/create' },
	issueOfTheWeekEdit: { path: '/issueOfTheWeek/:id' },

	magazineCategoryList: { path: '/magazine/categories' },
	magazineCategoryCreate: { path: '/magazine/categories/new' },
	magazineCategoryEdit: { path: '/magazine/categories/:id' },

	wikiCategoryList: { path: '/wiki/categories' },
	wikiCategoryCreate: { path: '/wiki/categories/new' },
	wikiCategoryEdit: { path: '/wiki/categories/:id' },

	podcastCategoryList: { path: '/podcast/categories' },
	podcastCategoryCreate: { path: '/podcast/categories/new' },
	podcastCategoryEdit: { path: '/podcast/categories/:id' },

	tvCategoryList: { path: '/tv/categories' },
	tvCategoryCreate: { path: '/tv/categories/new' },
	tvCategoryEdit: { path: '/tv/categories/:id' },

	productNewsCategoryList: { path: '/productNews/categories' },
	productNewsCategoryCreate: { path: '/productNews/categories/new' },
	productNewsCategoryEdit: { path: '/productNews/categories/:id' },

	eventsPage: { path: '/events/page' },
	eventList: { path: '/events' },
	eventCreate: { path: '/events/new' },
	eventEdit: { path: '/events/:id' },

	fileList: { path: '/files' },
	fileCreate: { path: '/files/new' },
	fileEdit: { path: '/files/:id' },
	fileCategoryList: { path: '/fileCategories' },

	formList: { path: '/forms' },
	formCreate: { path: '/forms/new' },
	formEdit: { path: '/forms/:id' },

	genericPageList: { path: '/pages' },
	genericPageCreate: { path: '/pages/new' },
	genericPageEdit: { path: '/pages/:id' },

	headerMenu: { path: '/header-menu' },
	footerMenu: { path: '/footer-menu' },

	searchPage: { path: '/search' },
	errorPage: { path: '/error' },

	wikiPage: { path: '/wikipage' },
	podcastsPage: { path: '/podcastspage' },
	tvPage: { path: '/tvpage' },
	productNewsPage: { path: '/productNewspage' },

	filesPage: { path: '/filespage' },
	webinarsPage: { path: '/webinarspage' },

	webinarList: { path: '/webinars' },
	webinarCreate: { path: '/webinars/new' },
	webinarEdit: { path: '/webinars/:id' },

	productOwnerList: { path: '/productOwners' },

	webinarOrganizerList: { path: '/webinarorganizers' },
	webinarOrganizerCreate: { path: '/webinarorganizers/new' },
	webinarOrganizerEdit: { path: '/webinarorganizers/:id' },

	webinarCategoryList: { path: '/webinarcategories' },
	webinarCategoryCreate: { path: '/webinarcategories/new' },
	webinarCategoryEdit: { path: '/webinarcategories/:id' },

	webinarLabelList: { path: '/webinarlabels' },
	webinarLabelCreate: { path: '/webinarlabels/new' },
	webinarLabelEdit: { path: '/webinarlabels/:id' },

	bannerList: { path: '/banners' },
	bannerCreate: { path: '/banners/new' },
	bannerEdit: { path: '/banners/:id' },

	storyList: { path: '/stories' },
	storyCreate: { path: '/stories/new' },
	storyEdit: { path: '/stories/:id' },

	authorList: { path: '/authors' },
	authorCreate: { path: '/authors/new' },
	authorEdit: { path: '/authors/:id' },

	visitorList: { path: '/visitors' },
	visitorDetail: { path: '/visitors/:id' },

	mailingListList: { path: '/mailing-lists' },
	mailingListCreate: { path: '/mailing-lists/new' },
	mailingListEdit: { path: '/mailing-lists/:id' },

	surveyList: { path: '/surveys' },
	surveyCreate: { path: '/surveys/new' },
	surveyEdit: { path: '/surveys/:id' },
	surveyResults: { path: '/surveys/:id/results' },

	redirects: { path: '/redirects/' },

	locales: { path: '/locales' },

	...translationRoutes(),

	// tenant
	tenantChangePassword: { path: '/change-password' },
	tenantUsers: { path: '/users' },
	tenantInviteUser: { path: '/invite-user' },
	tenantEditUser: { path: '/edit-user/:id' },
}
const config: ProjectConfig[] = [
	{
		project: 'lmc',
		stage: 'live',
		component: () => import('./live'),
		routes: routes,
	},
]

export default config
