import {
	AltPersistButton,
	Component,
	CreatePage,
	EditPage,
	Field,
	NavigateBackButton,
	PageLinkButton,
	TableCell,
	TablePage,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { EditButton, LinkUrlField, LocaleSideDimension } from '../../components'
import {
	representativeCategoryCreatePageName,
	representativeCategoryEditPageName,
	representativeCategoryListPageName,
} from './RepresentativePageNames'

export const representativeCategoryRoutes = {
	[representativeCategoryListPageName]: { path: '/representatives/categories' },
	[representativeCategoryCreatePageName]: { path: '/representatives/categories/new' },
	[representativeCategoryEditPageName]: { path: '/representatives/categories/:id' },
}

const listPageName = representativeCategoryListPageName
const createPageName = representativeCategoryCreatePageName
const editPageName = representativeCategoryEditPageName
const entityName = 'RepresentativeCategory'
const addNewTitle = 'Přidat kategorii referencí'
const listingTitle = 'Kategorie referencí'
const editTitle = 'Upravit kategorii referencí'

const grid = (
	<TableCell>
		<Field field="internalName" />
	</TableCell>
)
export const RepresentativeCategoryForm = Component(
	() => (
		<>
			<TextField field="internalName" label="Interní název" />
			<LinkUrlField derivedFrom="internalName" label="URL" persistedSoftPrefix="reference/" />
			<LocaleSideDimension>
				<TextField field="name" label="Název" />
			</LocaleSideDimension>
		</>
	),
	'RepresentativeCategoryForm',
)

export const RepresentativeCategoryCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
	>
		<RepresentativeCategoryForm />
	</CreatePage>
)
export const RepresentativeCategoryEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<RepresentativeCategoryForm />
	</EditPage>
)

export const RepresentativeCategoryListPage = (
	<TablePage
		pageName={listPageName}
		entities={entityName}
		rendererProps={{
			title: listingTitle,
			actions: <PageLinkButton to={createPageName}>{addNewTitle}</PageLinkButton>,
		}}
	>
		{grid}
		<TableCell shrunk>
			<EditButton pageName={editPageName} />
		</TableCell>
	</TablePage>
)
