import { ProjectConfig } from '@contember/admin'

const createRoutesForEntity = (entityName: string) => {
	const baseRoute = `/${entityName.toLowerCase()}`
	return {
		[`multiEdit${entityName}`]: { path: baseRoute + '/multiEdit' },
		[`create${entityName}`]: { path: baseRoute + '/new' },
		[`edit${entityName}`]: {
			path: baseRoute + '/:id([0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12})',
		},
		[`list${entityName}`]: { path: baseRoute },
	}
}

const routes = {
	dashboard: { path: '/' },

	...createRoutesForEntity('City'),
	...createRoutesForEntity('HelpRequestType'),
	...createRoutesForEntity('Partner'),
	...createRoutesForEntity('Volunteer'),
	...createRoutesForEntity('HelpRequest'),
	...createRoutesForEntity('Message'),
	volunteersByCity: { path: '/volunteers-by-city/:id' },
	helpRequestDetail: {
		path:
			'/helprequest/detail/:id([0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12})',
	},
	helpRequestCustomList: { path: '/helprequest/list' },
	helpRequestCustomCreate: { path: '/helprequest/detail/new' },

	helpRequestFreeList: { path: '/helprequest/list/free' },

	helpRequestOneTimeFreeList: { path: '/helprequest/list/onetime/free' },
	helpRequestOneTimeProgressList: { path: '/helprequest/list/onetime/progress' },
	helpRequestOneTimeEndedList: { path: '/helprequest/list/onetime/ended' },

	helpRequestRepeatedFreeList: { path: '/helprequest/list/repeated/free' },
	helpRequestRepeatedProgressList: { path: '/helprequest/list/repeated/progress' },
	helpRequestRepeatedEndedList: { path: '/helprequest/list/repeated/ended' },

	tenantChangePassword: { path: '/change-password' },
	tenantUsers: { path: '/users' },
	tenantInviteUser: { path: '/invite-user' },
	tenantEditUser: { path: '/edit-user/:id' },
}
const config: ProjectConfig[] = [
	{
		project: 'skautseniorhelp',
		stage: 'live',
		component: () => import('./src'),
		routes: routes,
	},
]

export default config
