import { blogArticleRoutes } from './BlogArticlePages'
import { blogCategoryRoutes } from './BlogCategoryPages'

export * from './BlogArticlePages'
export * from './BlogCategoryPages'
export * from './BlogPageNames'

export const blogRoutes = {
	...blogArticleRoutes,
	...blogCategoryRoutes,
}
