import {
	AltPersistButton,
	Component,
	CreatePage,
	EditPage,
	Field,
	Literal,
	MultiSelectField,
	NavigateBackButton,
	PageLinkButton,
	SelectField,
	TableCell,
	TablePage,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { EditButton, FileField, ImageField, LocaleSideDimension } from '../../components'
import { documentCreatePageName, documentEditPageName, documentListPageName } from './DocumentPageNames'

export const documentItselfRoutes = {
	[documentListPageName]: { path: '/documents' },
	[documentCreatePageName]: { path: '/documents/new' },
	[documentEditPageName]: { path: '/documents/:id' },
}

const listPageName = documentListPageName
const createPageName = documentCreatePageName
const editPageName = documentEditPageName
const entityName = 'Document'
const addNewTitle = 'Přidat nový dokument'
const listingTitle = 'Dokumenty'
const editTitle = 'Upravit dokument'

const grid = (
	<TableCell>
		<Field field="internalName" />
	</TableCell>
)
export const DocumentForm = Component(
	() => (
		<>
			<TextField field="internalName" label="Interní název" />
			<SelectField
				label="Předdefinovaná ikonka"
				options={[
					{ value: new Literal('green'), label: 'Zelená' },
					{ value: new Literal('img'), label: 'Obrázek' },
					{ value: new Literal('lightGray'), label: 'Světle šedá' },
					{ value: new Literal('pdf'), label: 'PDF' },
					{ value: new Literal('tech'), label: 'Tech' },
				]}
				field="predefinedIcon"
				allowNull={true}
			/>
			<ImageField field={'ownIcon'} label={'Vlastní ikonka'} allowImageDisconnect />
			<LocaleSideDimension>
				<TextField field="name" label="Název" />
				<FileField field="file" label="Soubor" />
			</LocaleSideDimension>
			<MultiSelectField field="categories" label="Kategorie" options="DocumentCategory.internalName" />
		</>
	),
	'DocumentForm',
)

export const DocumentCreatePage = (
	<CreatePage
		entity={entityName}
		pageName={createPageName}
		rendererProps={{
			title: addNewTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: listPageName })}
	>
		<DocumentForm />
	</CreatePage>
)
export const DocumentEditPage = (
	<EditPage
		entity={{ entityName, where: '(id = $id)' }}
		pageName={editPageName}
		rendererProps={{
			title: editTitle,
			navigation: <NavigateBackButton to={listPageName}>{listingTitle}</NavigateBackButton>,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<DocumentForm />
	</EditPage>
)

export const DocumentListPage = (
	<TablePage
		pageName={listPageName}
		orderBy="internalName asc"
		entities={entityName}
		rendererProps={{
			title: listingTitle,
			actions: <PageLinkButton to={createPageName}>{addNewTitle}</PageLinkButton>,
		}}
	>
		{grid}
		<TableCell shrunk>
			<EditButton pageName={editPageName} />
		</TableCell>
	</TablePage>
)
